import { Button, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import React from 'react';
import { Carousel } from 'react-responsive-carousel';
import 'react-responsive-carousel/lib/styles/carousel.min.css'; // requires a loader
import { Link } from 'react-router-dom';
import backgroundOne from '../images/home/plc.jpg';
import backgroundTwo from '../images/automation.jpg';
import backgroundThree from '../images/background3.jpg';
import backgroundFour from '../images/background4.jpg';
import backgroundFive from '../images/background5.jpg';
const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    height: 'calc(100vh-40)',
  },
  carousel: {
    position: 'absolute',
    height: 'calc(100vh)',
    zIndex: '4',
    userSelect: 'none',
  },
  image: {
    height: 'calc(100vh*0.8)',
  },
  content: {
    color: 'white',
    zIndex: '5',
    width: '100%',
    marginTop: 100,
    position: 'absolute',
  },

  buttonContent: {
    height: '52px',
    width: '134px',
    color: 'white',
    paddingTop: 14,
    alignItems: 'right',
    justifyContent: 'center',
    paddingBottom: 14,
    paddingRight: 20,
    paddingLeft: 20,
    borderRadius: 0,
    fontSize: 12,
    background: '#fe6600',
    marginTop: 50,
    transition: '0.5s',
    '&:hover': {
      background: '#0e92d0',
    },
  },
}));

const CarouselComponent = () => {
  const classes = useStyles();
  return (
    <Carousel
      autoPlay
      infiniteLoop
      showIndicators={false}
      showStatus={false}
      showThumbs={false}
    >
      <div className='animate__animated animate__pulse'>
        <div className={classes.content}>
          <Typography style={{ marginBottom: 50 }} variant='h6' component='h6'>
            <strong> We build your vision...</strong>
          </Typography>
          <Typography variant='h2' component='h2' style={{ margin: '5px' }}>
            <strong> Automation,Controls & Consulting</strong>
          </Typography>
          <div className='animate__animated animate__backInLeft'>
            <Link
              to='/contact'
              style={{ color: 'white', textDecoration: 'none' }}
            >
              <Button variant='contained' className={classes.buttonContent}>
                <strong>GET QUOTE</strong>
              </Button>
            </Link>
          </div>
        </div>
        <div className='animate__animated animate__zoomIn'>
          <img
            alt='about'
            className={classes.image}
            style={{ objectFit: 'cover' }}
            src={backgroundTwo}
          />
        </div>
      </div>
      <div>
        <div className={classes.content}>
          <div
            style={{
              textAlign: 'left',
              marginLeft: '20%',
            }}
          >
            <Typography variant='h2' component='h2'>
              <strong> Automation</strong>
            </Typography>
            <Typography
              style={{ marginBottom: 50, marginTop: 50 }}
              variant='h6'
              component='h6'
            >
              <strong>
                PLC & HMI Programming,Variable Frequency Drive Installation &
                Commission
              </strong>
            </Typography>

            <Link
              to='/service-category/automation'
              style={{ color: 'white', textDecoration: 'none' }}
            >
              <Button variant='contained' className={classes.buttonContent}>
                <strong>Learn More</strong>
              </Button>
            </Link>
          </div>
        </div>
        <img
          alt='automation'
          className={classes.image}
          style={{ objectFit: 'cover' }}
          src={backgroundOne}
        />
      </div>
      <div>
        <div className={classes.content}>
          <div
            style={{
              textAlign: 'left',
              marginLeft: '20%',
            }}
          >
            {' '}
            <Typography
              style={{ marginBottom: 50 }}
              variant='h2'
              component='h2'
            >
              <strong> Controls</strong>
            </Typography>
            <Typography variant='h6' component='h6'>
              <strong>
                {' '}
                Electrical Design,Product replacements,Retrofits &
                Upgrades,Support and Maintenance.
              </strong>
            </Typography>
            <Link
              to='/service-category/controls'
              style={{ color: 'white', textDecoration: 'none' }}
            >
              <Button variant='contained' className={classes.buttonContent}>
                <strong>Learn More</strong>
              </Button>
            </Link>
          </div>
        </div>
        <img
          alt='controls'
          className={classes.image}
          style={{ objectFit: 'cover' }}
          src={backgroundThree}
        />
      </div>
      <div>
        <div className={classes.content}>
          <div
            style={{
              textAlign: 'left',
              marginLeft: '20%',
              paddingRight: '10%',
            }}
          >
            <Typography
              style={{ marginBottom: 50 }}
              variant='h2'
              component='h2'
            >
              <strong> Instrumentation</strong>
            </Typography>

            <Typography variant='h6' component='h6'>
              <strong>Automation and instrumentation</strong>
            </Typography>

            <Link
              to='/service-category/instrumentation'
              style={{ color: 'white', textDecoration: 'none' }}
            >
              <Button variant='contained' className={classes.buttonContent}>
                <strong>Learn More</strong>
              </Button>
            </Link>
          </div>
        </div>
        <img
          alt='consultation'
          className={classes.image}
          style={{ objectFit: 'cover' }}
          src={backgroundFive}
        />
      </div>

      <div>
        <div className={classes.content}>
          <div
            style={{
              textAlign: 'left',
              marginLeft: '20%',
            }}
          >
            <Typography
              style={{ marginBottom: 50 }}
              variant='h2'
              component='h2'
            >
              <strong> Consulting</strong>
            </Typography>

            <Typography variant='h6' component='h6'>
              <strong>
                Benefit from our expertise.We offer hourly consultation services
              </strong>
            </Typography>

            <Link
              href='/#services'
              style={{ color: 'white', textDecoration: 'none' }}
            >
              <Button variant='contained' className={classes.buttonContent}>
                <strong>Learn More</strong>
              </Button>
            </Link>
          </div>
        </div>
        <img
          alt='consultation'
          className={classes.image}
          style={{ objectFit: 'cover' }}
          src={backgroundFour}
        />
      </div>
    </Carousel>
  );
};

export default CarouselComponent;
