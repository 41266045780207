import React from "react";
import TopBar from "../components/TopBar";
import MenuBar from "../components/MenuBar";
import AboutWelcomeSection from "../components/AboutComponents/AboutWelcomeSection";
import { Divider } from "@material-ui/core";
import AboutDescription from "../components/AboutComponents/AboutDescription";
import AboutQuote from "../components/AboutComponents/AboutQuote";
import Footer from "../components/Footer";
const About = () => {
	return (
		<>
			<TopBar />
			<Divider style={{ width: "100 %" }} />
			<MenuBar />
			<Divider style={{ width: "100 %" }} />
			<AboutWelcomeSection />
			<AboutDescription />
			<AboutQuote />
			<Footer />
		</>
	);
};

export default About;
