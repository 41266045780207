import {
	Button,
	Container, Grid,
	Typography,


	useMediaQuery
} from "@material-ui/core";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import React from "react";
import ScrollAnimation from "react-animate-on-scroll";
import { Link } from "react-router-dom";
import quoteImage from "../../images/background2.jpg";
import LocationContact from "../LocationContact";
const useStyles = makeStyles((theme) => ({
	root: {
		fontFamily: "Roboto",
	},
	buttonContent: {
		color: "white",
		paddingTop: 14,
		paddingBottom: 14,
		paddingRight: 20,
		paddingLeft: 20,
		borderRadius: 0,
		fontSize: 12,
		background: "#fe6600",
		marginTop: "10vh",
		border: "2px solid #fe6600",
		marginBottom: "10vh",
		transition: "0.5s",
		"&:hover": {
			background: "#212121",
			border: "2px solid #fe6600",
		},
	},
	content: {
		backgroundColor: "#212121",
		marginBottom: "10vh",
	},
	buttonContentMore: {
		paddingTop: 14,
		paddingBottom: 14,
		paddingRight: 20,
		paddingLeft: 20,
		borderRadius: 0,
		marginLeft: "2%",
		fontSize: 12,
		textStyle: "bold",
		border: "2px solid #bdbdbd",
		color: "#00255b",
		marginTop: "10vh",
		marginBottom: "10vh",
		transition: "0.5s",

		"&:hover": {
			background: "#fe6600",
			color: "#ffffff",
		},
	},
	contentMore: { marginBottom: "10vh" },

	imageStyle: {
		objectFit: "contain",
		paddingRight: "5%",
		width: "20%",
	},
	textList: {
		color: "#828282",
		marginBottom: "2vh",
	},
	list: {
		paddingBottom: "2vh",
		marginRight: 0,
	},
}));
const AboutQuote = () => {
	const classes = useStyles();
	const theme = useTheme();
	const matches = useMediaQuery(theme.breakpoints.down("sm"));
	return (
		<div className={classes.root}>
			<Grid container className={classes.content}>
				<Grid
					container
					style={{
						alignContent: "center",
					}}
					item
					md="7"
					xs="12"
				>
					<ScrollAnimation
						animateOnce="true"
						animateIn="animate__animated animate__slideInUp"
					>
						<img
							alt="about"
							src={quoteImage}
							style={{
								width: matches ? "100%" : "70%",
								objectFit: "cover",
								height: "calc(100vh*0.6)",
							}}
						/>
					</ScrollAnimation>
				</Grid>
				<Grid
					direction="column"
					container
					style={{
						paddingTop: "calc(100vh*0.1)",
						alignContent: "center",
					}}
					item
					md="5"
					xs="12"
				>
					<Container style={{ display: "flex", flexDirection: "column" }}>
						<ScrollAnimation
							animateOnce="true"
							animateIn="animate__animated animate__slideInUp"
						>
							<Typography
								style={{
									color: "#ffffff",
									paddingBottom: "2vh",
									paddingLeft: "2%",
								}}
								component="h2"
								variant="h2"
							>
								<strong>Your vision becomes reality.</strong>
							</Typography>
						</ScrollAnimation>
						<ScrollAnimation
							animateOnce="true"
							animateIn="animate__animated animate__slideInUp"
						>
							<ul style={{ color: "#fe6600", fontSize: "15px" }}>
								<li className={classes.list}>
									<strong className={classes.textList}>
										{" "}
										Automation solutions
									</strong>
								</li>
								<li className={classes.list}>
									<strong className={classes.textList}>
										{" "}
										Control solutions
									</strong>
								</li>
								<li className={classes.list}>
									<strong className={classes.textList}>
										{" "}
										Instrumentation solutions
									</strong>
								</li>
								<li className={classes.list}>
									<strong className={classes.textList}>Consultancy</strong>
								</li>
							</ul>
						</ScrollAnimation>
						<ScrollAnimation
							animateOnce="true"
							animateIn="animate__animated animate__slideInUp"
						>
							<div>
								<Link to="/contact" style={{ textDecoration: "none" }}>
									<Button variant="contained" className={classes.buttonContent}>
										<strong>GET A QUOTE</strong>
									</Button>
								</Link>
							</div>
						</ScrollAnimation>
					</Container>
				</Grid>
			</Grid>
			<Container>
				<Grid container className={classes.contentMore}>
					<Grid container alignContent="center" item sm="5" xs="12">
						<div>
							<ScrollAnimation
								animateOnce="true"
								animateIn="animate__animated animate__slideInUp"
							>
								<Typography
									style={{ color: "#0e92d0", paddingBottom: 10, width: "70%" }}
									component={`${matches ? "h2" : "h3"}`}
									variant={`${matches ? "h2" : "h3"}`}
								>
									<strong>National reach, local attitude</strong>
								</Typography>
							</ScrollAnimation>
							<div>
								<ScrollAnimation
									animateOnce="true"
									animateIn="animate__animated animate__slideInUp"
								>
									<Link to="/contact" style={{ textDecoration: "none" }}>
										<Button
											variant="outlined"
											className={classes.buttonContentMore}
										>
											<strong>Contact Us</strong>
										</Button>
									</Link>
								</ScrollAnimation>
							</div>
						</div>
					</Grid>
					<Grid
						style={{ paddingRight: "5%", marginBottom: "10vh" }}
						container
						item
						sm="3"
						xs="12"
					>
						<ScrollAnimation
							animateOnce="true"
							animateIn="animate__animated animate__slideInUp"
						>
							<Typography
								style={{
									color: "#0e92d0",
									marginBottom: "2vh",
									paddingBottom: "1vh",
									borderBottom: "3px solid #fe6600",
								}}
								component="h6"
								variant="h6"
							>
								<strong>Easy Remote Support</strong>
							</Typography>
							<Typography
								style={{
									color: "#828282",
									fontFamily: "Roboto",
									marginBottom: "10vh",
									fontSize: "15px",
								}}
								component="body1"
								variant="p"
							>
								With our diverse skill sets, we will keep your automation
								projects on track and on budget. Whether you are an OEM who
								builds equipment or the end manufacture, we will be a partner
								you can rely on.
							</Typography>
						</ScrollAnimation>
					</Grid>
					<Grid container item sm="3" xs="12">
						<ScrollAnimation
							animateOnce="true"
							animateIn="animate__animated animate__slideInUp"
						>
							<Typography
								style={{
									color: "#0e92d0",
									marginBottom: "2vh",
									paddingBottom: "1vh",
									borderBottom: "3px solid #fe6600",
								}}
								component="h6"
								variant="h6"
							>
								<strong>Nationwide Travel</strong>
							</Typography>
							<Typography
								style={{
									color: "#828282",
									fontFamily: "Roboto",
									marginBottom: "10vh",
									fontSize: "15px",
								}}
								component="body1"
								variant="p"
							>
								With our diverse skill sets, we will keep your automation
								projects on track and on budget. Whether you are an OEM who
								builds equipment or the end manufacture, we will be a partner
								you can rely on.
							</Typography>
						</ScrollAnimation>
					</Grid>
				</Grid>
			</Container>
			<LocationContact />
		</div>
	);
};

export default AboutQuote;
