import {
  Grid,
  Link as Linked, Typography,
  useMediaQuery
} from '@material-ui/core';
import IconButton from '@material-ui/core/IconButton';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import ArrowDownwardIcon from '@material-ui/icons/ArrowDownward';
import clsx from 'clsx';
import React from 'react';
import ScrollAnimation from 'react-animate-on-scroll';
import automation from '../../images/solutions/automation.jpg';
import consultation from '../../images/solutions/consulting.jpg';
import controls from '../../images/solutions/controls.jpg';
import instrumentation from '../../images/solutions/instrumentation.png';

const useStyles = makeStyles((theme) => ({
  mainImage: {
    height: 'calc(60vh)',
    objectFit: 'cover',
    width: '100%',
  },
  readMore: {
    width: '100%',
    background: '#FE6600',
    height: 'calc(10vh)',
    transition: '1s',
    '&:hover': {
      background: '#222222',
      color: 'white',
    },
  },
  readText: {
    fontSize: '13px',
    letterSpacing: '0.54px',
    fontFamily: 'Roboto',
    color: '#000000',
    paddingLeft: '2%',
    whiteSpace: 'nowrap',
  },
  text: {
    color: 'white',
    paddingLeft: 'calc(2vh)',

    letterSpacing: '0.1rem',

    [theme.breakpoints.down('xs')]: {
      fontSize: '28px',
    },
    [theme.breakpoints.up('sm')]: {
      fontSize: '36px',
    },
    [theme.breakpoints.up('md')]: {
      paddingLeft: 'calc(10vh)',
      fontSize: '40px',
    },
  },
  serviceItems: {
    height: 'calc(60vh)',
  },
  contentArea: {
    height: 'calc(50vh)',
    display: 'flex',
    alignContent: 'center',
    alignItems: 'center',
    [theme.breakpoints.down('md')]: {
      height: 'calc(40vh)',
    },
  },
  expand: {
    transform: 'rotate(270deg)',
    marginLeft: 'auto',
    transition: theme.transitions.create('transform', {
      duration: theme.transitions.duration.shortest,
    }),
  },
  expandOpen: {
    transform: 'rotate(270deg)',
  },

  innerRead2: {
    width: '100%',
    display: 'flex',
    justifyContent: 'space-between',
    alignContent: 'center',
    alignItems: 'center',
  },
}));

const content = [
  {
    image: automation,
    title1: 'Automation',
    title2: '',
    c: '0',
  },
  {
    image: controls,
    title1: 'Controls',
    title2: '',
    c: '1',
  },
  {
    image: instrumentation,
    title1: 'Instrumentation',
    title2: '',
    c: '0',
  },
  {
    image: consultation,
    title1: 'Consulting',
    title2: '',
    c: '1',
  },
];

const Content = () => {
  const classes = useStyles();
  const theme = useTheme();
  const matchesMd = useMediaQuery(theme.breakpoints.up('md'));
  const matchesLg = useMediaQuery(theme.breakpoints.up('lg'));

  return (
    <div>
      {content.map((item, index) => {
        let color = '#ffffff';
        let url = '';
        if (index > 8) {
          url = `/service-category/description/${encode(`${item.title1}`)}`;
        } else {
          url = url = `/service-category/description/${encode(
            `${item.title1}`,
          )}`;
        }
        switch (item.c) {
          case '0':
            color = '#222222';
            break;
          case '1':
            break;
          case '2':
            color = '#fafafa';
            break;

          default:
            break;
        }
        if (index % 2 === 0) {
          return (
            <ScrollAnimation
              animateOnce='true'
              animateIn='animate__animated animate__slideInUp'
            >
              <Grid container>
                <Grid
                  className={classes.serviceItems}
                  alignItems='center'
                  item
                  xs={12}
                  md={6}
                >
                  <img
                    className={classes.mainImage}
                    src={item.image}
                    alt='service'
                  />
                </Grid>
                <Grid
                  style={{ background: `${color}`, flexDirection: 'column' }}
                  container
                  alignItems='center'
                  direction='column'
                  item
                  xs={12}
                  md={6}
                >
                  <div
                    className={classes.contentArea}
                    alignItems='center'
                    item
                    xs={12}
                  >
                    <Typography component='h4' variant='h4'>
                      <div
                        className={classes.text}
                        style={{
                          fontWeight: 100,
                          color: `${item.c !== '0' && '#222222'}`,
                        }}
                      >
                        {item.title1}
                      </div>
                    </Typography>
                  </div>
                  <div
                    className={classes.readMore}
                    style={{
                      textDecoration: 'none',
                      display: 'flex',
                      alignContent: 'center',
                      alignItems: 'center',
                    }}
                  >
                    <Linked
                      href={url}
                      style={{
                        textDecoration: 'none',
                        width: '100%',
                      }}
                    >
                      <div
                        className={classes.innerRead2}
                        style={{
                          flexDirection: `row`,
                        }}
                      >
                        <span className={classes.readText}>
                          <strong>{`READ MORE`}</strong>
                        </span>

                        <IconButton
                          style={{ margin: 0 }}
                          className={clsx(classes.expand, {
                            [classes.expandOpen]: !matchesMd,
                          })}
                          aria-expanded={!matchesMd}
                          aria-label='show more'
                        >
                          <ArrowDownwardIcon fontSize='small' />
                        </IconButton>
                      </div>
                    </Linked>
                  </div>
                </Grid>
              </Grid>
            </ScrollAnimation>
          );
        } else {
          return (
            <ScrollAnimation
              animateOnce='true'
              animateIn='animate__animated animate__slideInUp'
            >
              <Grid
                direction={`${!matchesMd ? 'column-reverse' : 'row'}`}
                container
              >
                <Grid
                  style={{ background: `${color}` }}
                  container
                  alignItems='center'
                  direction='column'
                  item
                  xs={12}
                  md={6}
                >
                  <div className={classes.contentArea}>
                    <Typography component='h4' variant='h4'>
                      <div
                        className={classes.text}
                        style={{
                          fontWeight: 300,
                          color: `${item.c !== '0' && '#222222'}`,
                        }}
                      >
                        {item.title1}
                      </div>
                    </Typography>

                    <Typography component='h4' variant='h4'>
                      <strong
                        className={classes.text}
                        style={{ color: `${item.c !== '0' && '#222222'}` }}
                      >
                        {item.title2}
                      </strong>
                    </Typography>
                  </div>
                  <div
                    className={classes.readMore}
                    style={{
                      textDecoration: 'none',
                      display: 'flex',
                      alignContent: 'center',
                      alignItems: 'center',
                    }}
                  >
                    <Linked
                      href={url}
                      style={{ textDecoration: 'none', width: '100%' }}
                    >
                      <div
                        className={classes.innerRead2}
                        style={{
                          flexDirection: `row`,
                        }}
                      >
                        <span className={classes.readText}>
                          <strong>{`READ MORE`}</strong>
                        </span>

                        <IconButton
                          style={{ margin: 0 }}
                          className={clsx(classes.expand, {
                            [classes.expandOpen]: !matchesMd,
                          })}
                          aria-expanded={!matchesMd}
                          aria-label='show more'
                        >
                          <ArrowDownwardIcon fontSize='small' />
                        </IconButton>
                      </div>
                    </Linked>
                  </div>
                </Grid>
                <Grid
                  style={{ height: 'calc(60vh)' }}
                  alignItems='center'
                  item
                  xs={12}
                  md={6}
                >
                  <img
                    className={classes.mainImage}
                    src={item.image}
                    alt='service'
                  />
                </Grid>
              </Grid>
            </ScrollAnimation>
          );
        }
      })}
    </div>
  );
};
export const decode = (passed) => {
  try {
    return decodeURIComponent(passed);
  } catch (error) {
    return 'Something went wrong';
  }
};
export const encode = (passed) => {
  try {
    return encodeURIComponent(passed);
  } catch (error) {
    return 'Something went wrong';
  }
};

export default Content;
