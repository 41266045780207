import React from "react";
import { Grid, Typography, Button } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import displayAbout from "../images/background3.jpg";
import ScrollAnimation from "react-animate-on-scroll";
import { Link } from "react-router-dom";

const useStyles = makeStyles((theme) => ({
	root: {
		backgroundColor: "#f5f5f5",
	},
	image: {
		height: "calc(100vh*0.8)",
		width: "100%",
		objectFit: "cover",
		[theme.breakpoints.down("sm")]: {
			height: "calc(100vh*0.6)",
		},
	},
	buttonContent: {
		width: "134px",
		height: "52px",
		color: "white",
		paddingTop: 14,
		paddingBottom: 14,
		paddingRight: 20,
		paddingLeft: 20,
		borderRadius: 0,
		fontSize: 12,
		background: "#fe6600",
		marginTop: 50,
		transition: "0.5s",
		"&:hover": {
			background: "#0e92d0",
		},
	},
}));

const AboutUsLink = () => {
	const classes = useStyles();
	return (
		<div className={classes.root}>
			<Grid container>
				<Grid item md="4" sm="12" xs="12">
					<img alt="about" className={classes.image} src={displayAbout} />
				</Grid>
				<Grid style={{ padding: 50 }} container item md="8" sm="12" xs="12">
					<ScrollAnimation
						animateOnce="true"
						animateIn="animate__animated animate__slideInUp"
					>
						<Typography
							style={{ color: "#0e92d0" }}
							component="h4"
							variant="h4"
						>
							<strong>We are the future</strong>
						</Typography>
						<Grid container item xs="12">
							<Grid
								style={{ padding: 10 }}
								direction="column"
								item
								sm="6"
								xs="12"
							>
								<Typography
									style={{ color: "#fe6600" }}
									component="h6"
									variant="h6"
								>
									<strong>Why choose us?</strong>
								</Typography>
								<Typography
									style={{ color: "#828282", marginBottom: "20" }}
									component="body1"
									variant="p"
								>
									We will not just do business with you but endeavour to provide
									to you a sound solution and advise with a focus on long term
									sustainability and consistent result. We intend to be your
									business partner and not just a service provider.
								</Typography>
								<div>
									<Link
										to="/about"
										style={{ color: "white", textDecoration: "none" }}
									>
										<Button
											variant="contained"
											className={classes.buttonContent}
										>
											<strong>About Us</strong>
										</Button>{" "}
									</Link>
								</div>
							</Grid>
							<Grid style={{ padding: 10 }} item sm="6" xs="12">
								<Typography
									style={{ color: "#fe6600" }}
									component="h6"
									variant="h6"
								>
									<strong>You ask we create</strong>
								</Typography>
								<Typography
									style={{ color: "#828282" }}
									component="body1"
									variant="p"
								>
									We are a team of experienced automation experts with vast
									project experience in industrial automation.
								</Typography>
							</Grid>
						</Grid>
					</ScrollAnimation>
				</Grid>
			</Grid>
		</div>
	);
};

export default AboutUsLink;
