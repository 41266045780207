import { Container, Grid, Link, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import React from 'react';
import email from '../images/email.png';
import map from '../images/map.png';
import support from '../images/support.png';
import { my_strings } from '../values/strings';
const useStyles = makeStyles((theme) => ({
  root: {
    fontFamily: 'Roboto',
    fontSize: '15px',
  },
  buttonContent: {
    color: 'white',
    paddingTop: 14,
    paddingBottom: 14,
    paddingRight: 20,
    paddingLeft: 20,
    borderRadius: 0,
    fontSize: 12,
    background: '#fe6600',
    marginTop: '10vh',
    border: '2px solid #fe6600',
    marginBottom: '10vh',
    transition: '0.5s',
    '&:hover': {
      background: '#212121',
      border: '2px solid #fe6600',
    },
  },
  content: {
    backgroundColor: '#212121',
    marginBottom: '10vh',
  },
  buttonContentMore: {
    paddingTop: 14,
    paddingBottom: 14,
    paddingRight: 20,
    paddingLeft: 20,
    borderRadius: 0,
    marginLeft: '2%',
    fontSize: 12,
    textStyle: 'bold',
    border: '2px solid #bdbdbd',
    color: '#00255b',
    marginTop: '10vh',
    marginBottom: '10vh',
    transition: '0.5s',

    '&:hover': {
      background: '#fe6600',
      color: '#ffffff',
    },
  },
  contentMore: { marginBottom: '10vh' },

  imageStyle: {
    objectFit: 'contain',
    paddingRight: '5%',
    width: '20%',
  },
  textList: {
    color: '#828282',
    marginBottom: '2vh',
  },
  list: {
    paddingBottom: '2vh',
    marginRight: 0,
  },
}));
const AboutQuote = () => {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <Container>
        <Grid container style={{ paddingBottom: 'calc(5vh)' }}>
          <Grid
            style={{ marginBottom: '10vh' }}
            container
            direction='row'
            item
            sm='4'
            xs='12'
          >
            <img className={classes.imageStyle} alt='map' src={map} />
            <div>
              <Typography
                style={{
                  color: '#0e92d0',
                  paddingBottom: 10,
                }}
                component='h6'
                variant='h6'
              >
                <strong>Location</strong>
              </Typography>
              <Typography
                style={{ color: '#828282', marginBottom: '20' }}
                component='body1'
                variant='p'
              >
               3rd Floor,Winstonia Business Centre.Airport North Road ,Off Mombasa Road
              </Typography>
            </div>
          </Grid>
          <Grid
            style={{ marginBottom: '10vh' }}
            container
            direction='row'
            item
            sm='4'
            xs='12'
          >
            <img className={classes.imageStyle} alt='map' src={support} />
            <div style={{ display: 'flex', flexDirection: 'column' }}>
              <Typography
                style={{
                  color: '#0e92d0',
                  paddingBottom: 10,
                }}
                component='h6'
                variant='h6'
              >
                <strong>Phone</strong>
              </Typography>
              <Link
                href={`tel:${my_strings.contact.phonenumber}`}
                style={{
                  color: '#888',
                  textDecoration: 'none',
                  marginBottom: '10px',
                }}
              >
                {my_strings.contact.phonenumber}
              </Link>
            </div>
          </Grid>
          <Grid
            style={{ marginBottom: '10vh' }}
            container
            direction='row'
            item
            sm='4'
            xs='12'
          >
            <img className={classes.imageStyle} alt='map' src={email} />
            <div style={{ display: 'flex', flexDirection: 'column' }}>
              <Typography
                style={{
                  color: '#0e92d0',
                  paddingBottom: 10,
                }}
                component='h6'
                variant='h6'
              >
                <strong>Email Us</strong>
              </Typography>

              <Typography
                style={{ color: '#828282', marginBottom: '20' }}
                component='body1'
                variant='p'
              >
                {my_strings.contact.info_email}
              </Typography>
              <Typography
                style={{ color: '#828282', marginBottom: '20' }}
                component='body1'
                variant='p'
              >
                {my_strings.contact.sales_email}
              </Typography>
            </div>
          </Grid>
        </Grid>
      </Container>
    </div>
  );
};

export default AboutQuote;
