import { Grid, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import RightIcon from '@material-ui/icons/ChevronRight';
import React from 'react';
import { Link, useHistory, useLocation } from 'react-router-dom';
import titleImage from '../../images/top5.jpg';
import { decode } from './Content';

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundImage: `url(${titleImage})`,
    backgroundSize: 'cover',
    height: 'calc(30vh)',
  },
  title1: {
    color: 'white',

    textAlign: 'left',
    fontWeight: 700,
    letterSpacing: '0.1rem',

    [theme.breakpoints.down('xs')]: {
      fontSize: '28px',
    },
    [theme.breakpoints.up('sm')]: {
      fontSize: '36px',
    },
    [theme.breakpoints.up('md')]: {
      paddingLeft: '0px',
      fontSize: '40px',
    },
  },
  directionText: {
    fontSize: '13px',
    fontFamily: 'Roboto',
    color: 'white',
    paddingLeft: 'calc(2vh)',
    alignItems: 'baseline',

    paddingRight: '5vw',
    [theme.breakpoints.up('md')]: {
      alignItems: 'center',
    },
  },
  serviceContainer: {
    alignItems: 'flex-end',
    paddingLeft: 'calc(2vh)',
    [theme.breakpoints.up('md')]: {
      alignItems: 'center',
    },
  },
  linkStyling: {
    textDecoration: 'none',
    color: 'white',
    transition: '0.5s',
    '&:hover': {
      textDecoration: 'underline',
    },
  },
}));

const Title = () => {
  const location = useLocation();
  const classes = useStyles();
  let history = useHistory();
  let title = '';
  let position = 0;
  let link = '';

  try {
    if (location.pathname.split('solutions/')[1] !== undefined) {
      switch (decode(location.pathname.split('solutions/')[1])) {
        case 'PLC & HMI':
          link = 'PLCs & HMIs';

          break;
        case 'Variable Frequency Drives':
          link = 'Variable Frequency Drives';
          break;
        case 'SCADA':
          link = 'SCADA';
          break;
        case 'Remote Monitoring':
          link = 'Remote Monitoring';
          break;
        case 'Automated School Bell':
          link = 'Automated School Bell';
          break;
        case 'PUMP Monitoring System':
          link = 'PUMP Monitoring System';
          break;
        case 'Industrial Wireless Communication':
          link = 'Industrial Wireless Communication';
          break;
        case 'Low voltage Switchboard and distribution boards':
          link = 'Low voltage distribution Board';
          break;
        case 'Motor Control Centre':
          link = 'Motor Control Centre';
          break;
        case 'Automatic Power Factor Correction Panels (APFC)':
          link = 'Automatic Power Factor Correction Panels (APFC)';
          break;
        case 'Automatic mains failure (AMF) panel':
          link = 'Automatic mains failure (AMF) panel';
          break;
        case 'Instrumentation Section':
          link = 'Instrumentation Section';
          break;
        case 'Sensors':
          link = 'Sensors';
          break;
        case 'Testing & Test Equipment':
          link = 'Testing & Test Equipment';
          break;
        case 'Consulting':
          link = 'Consulting';
          break;
        case 'Pressure Instrumentation':
          link = 'Pressure Instrumentation';
          break;
        case 'Temperature instrumentation':
          link = 'Temperature instrumentation';
          break;
        case 'Flow Instrumentation':
          link = 'Flow Instrumentation';
          break;
        case 'Level Instrumentation':
          link = 'Level Instrumentation';
          break;

        default:
          break;
      }
    } else {
      console.log('It is an invalid page');
      //throw "Invalid Page";
    }
  } catch (error) {
    console.log(error);
    history.push('/');
  }

  return (
    <div>
      <Grid container className={classes.root}>
        <Grid
          className={classes.serviceContainer}
          container
          item
          xs={12}
          md={9}
        >
          <Typography component='h3' variant='h3'>
            <strong className={classes.title1}>{link}</strong>
          </Typography>
        </Grid>
        <Grid className={classes.directionText} item container xs={12} md={3}>
          <div
            style={{
              alignItems: 'center',
              display: 'flex',
              flexDirection: 'row',
            }}
          >
            <Link to='/' className={classes.linkStyling}>
              <div style={{ letterSpacing: '0.5px' }}> HOME</div>
            </Link>

            <RightIcon fontSize='small' />

            <>
              <Link to={`/solutions`} className={classes.linkStyling}>
                <div style={{ letterSpacing: '0.5px' }}> SOLUTIONS</div>
              </Link>
            </>
          </div>
        </Grid>
      </Grid>
    </div>
  );
};

export default Title;
