import React from "react";
import { Map, GoogleApiWrapper } from "google-maps-react";

const ContactSection = (props) => {
	const mapStyles = {
		width: `calc(100%)`,
		height: "calc(100vh*0.8)",
	};
	return (
		<div>
			<Map
				google={props.google}
				zoom={8}
				style={mapStyles}
				initialCenter={{ lat: 47.444, lng: -122.176 }}
			/>
		</div>
	);
};

export default GoogleApiWrapper({
	apiKey: "AIzaSyDZfOsvnoSvE_1Yc_Iz6cNyQY66X47VOOQ",
})(ContactSection);
