import React from "react";
import { Grid, Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import titleImage from "../../images/top5.jpg";
import RightIcon from "@material-ui/icons/ChevronRight";
import { Link } from "react-router-dom";

const useStyles = makeStyles((theme) => ({
	root: {
		backgroundImage: `url(${titleImage})`,
		backgroundSize: "cover",
		height: "calc(30vh)",
	},
	title1: {
		color: "white",
		paddingLeft: "calc(2vh)",
		fontWeight: 700,
		letterSpacing: "0.1rem",

		[theme.breakpoints.down("xs")]: {
			fontSize: "28px",
		},
		[theme.breakpoints.up("sm")]: {
			fontSize: "36px",
		},
		[theme.breakpoints.up("md")]: {
			paddingLeft: "calc(10vh)",
			fontSize: "40px",
		},
	},
	directionText: {
		fontSize: "13px",
		fontFamily: "Roboto",
		color: "white",
		paddingLeft: "calc(2vh)",
		alignItems: "baseline",
		[theme.breakpoints.up("md")]: {
			alignItems: "center",
		},
	},
	serviceContainer: {
		alignItems: "flex-end",
		[theme.breakpoints.up("md")]: {
			alignItems: "center",
		},
	},
	linkStyling: {
		textDecoration: "none",
		color: "white",
		transition: "0.5s",
		"&:hover": {
			textDecoration: "underline",
		},
	},
}));

const Title = () => {
	const classes = useStyles();
	return (
		<div>
			<Grid container className={classes.root}>
				<Grid
					className={classes.serviceContainer}
					container
					item
					xs={12}
					md={10}
				>
					<Typography component="h3" variant="h3">
						<strong className={classes.title1}> Solutions</strong>
					</Typography>
				</Grid>
				<Grid className={classes.directionText} item container xs={12} md={2}>
					<div
						style={{
							alignItems: "center",
							display: "flex",
							flexDirection: "row",
						}}
					>
						<Link to="/" className={classes.linkStyling}>
							<div style={{ letterSpacing: "0.5px" }}> HOME</div>
						</Link>

						<RightIcon fontSize="small" />

						<div style={{ letterSpacing: "0.5px" }}>SOLUTIONS</div>
					</div>
				</Grid>
			</Grid>
		</div>
	);
};

export default Title;
