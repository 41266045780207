import React from 'react';
import {
  makeStyles,
  createMuiTheme,
  responsiveFontSizes,
  ThemeProvider,
} from '@material-ui/core/styles';
import TopBar from '../components/TopBar';
import { Divider, Hidden, Typography, useMediaQuery } from '@material-ui/core';
import { useTheme } from '@material-ui/core/styles';
import MenuBar from '../components/MenuBar';
import CarouselComponent from '../components/Carousel';
import Services from '../components/Services';
import AboutUsLink from '../components/AboutUsLink';
import SectionQuote from '../components/SectionQuote';
import ContactSection from '../components/ContactSection';
import Footer from '../components/Footer';
import ContactBlock from '../components/ContactBlock';
import LogoCarousel from '../components/home/LogoCarousel';

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    height: 'calc(100vh)-40',
  },
  carousel: {
    position: 'absolute',
    height: 'calc(100vh)',
    zIndex: '4',
    userSelect: 'none',
  },
  image: {
    height: 'calc(100vh/1)',
  },
  close: {
    color: 'white',
    fontSize: 40,
    zIndex: '5',
    position: 'absolute',
  },
  textLogo: {
    color: '#0E92D0',
  },
  contact: {},
  carouselBrand: {
    [theme.breakpoints.up('sm')]: {
      margin: '3rem',
    },
  },
}));

const Home = () => {
  const classes = useStyles();
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.down('sm'));

  let themes = createMuiTheme({
    typography: {
      fontFamily: ['Roboto'].join(','),
    },
  });
  themes = responsiveFontSizes(themes);
  return (
    <ThemeProvider theme={themes} className={classes.root}>
      <div>
        <TopBar />
        <Hidden smDown>
          <Divider style={{ width: '100 %' }} />
        </Hidden>
      </div>

      <MenuBar />
      <Divider style={{ width: '100 %' }} />
      <CarouselComponent />
      <Services />
      <div>
        <AboutUsLink />
      </div>
      <div className={classes.carouselBrand} style={{ textAlign: 'center' }}>
        <Typography className={classes.textLogo} variant={'h3'}>
          <strong> Some of the Brands we Support</strong>
        </Typography>
        <LogoCarousel />
      </div>
      <SectionQuote />
      <div className={classes.contact}>
        <ContactSection />
      </div>
      <div
        style={{
          marginTop: `${matches ? 'calc(100vh*0.1)' : 'calc(100vh*0.1)'}`,
        }}
      >
        <ContactBlock />
      </div>
      <Footer />
    </ThemeProvider>
  );
};

export default Home;
