import { Button, Divider, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import React, { useEffect, useState } from 'react';
import { Link, useHistory, useLocation } from 'react-router-dom';
import Footer from '../components/Footer';
import MenuBar from '../components/MenuBar';
import { decode } from '../components/service/Content';
import Content from '../components/service/ServiceCategory';
import TopTitle from '../components/service/SubMiniDetailedTitle';
import TopBar from '../components/TopBar';
import remote from '../images/Remote.jpg';
import asb1 from '../images/service-category/automation/asb/asb-image1.jpeg';
import asb2 from '../images/service-category/automation/asb/asb-image2.jpeg';
import asb3 from '../images/service-category/automation/asb/asb-image3.jpeg';
import iwc2 from '../images/service-category/automation/iwc/iwc-image2.jpg';
import iwc3 from '../images/service-category/automation/iwc/iwc-image3.jpg';
import iwc4 from '../images/service-category/automation/iwc/iwc-image4.jpeg';
import plc1 from '../images/service-category/automation/plc/plc-image1.jpg';
import plc2 from '../images/service-category/automation/plc/plc-image2.jpg';
import plc3 from '../images/service-category/automation/plc/plc-image3.jpg';
import pms2 from '../images/service-category/automation/pms/pms-image2.jpeg';
import scada1 from '../images/service-category/automation/scada/scada-image1.jpg';
import scada2 from '../images/service-category/automation/scada/scada-image2.jpg';
import vfd1 from '../images/service-category/automation/vfd/vfd-image1.jpg';
import vfd2 from '../images/service-category/automation/vfd/vfd-image2.jpg';
import vfd3 from '../images/service-category/automation/vfd/vfd-image3.jpeg';
import cs1 from '../images/service-category/consultation/cs-image1.jpg';
import amf1 from '../images/service-category/controls/amf/amf-image1.jpg';
import amf2 from '../images/service-category/controls/amf/amf-image2.jpg';
import lvcd1 from '../images/service-category/controls/lvcd/lvcd-image1.jpeg';
import lvcd2 from '../images/service-category/controls/lvcd/lvcd-image2.jpeg';
import lvcd3 from '../images/service-category/controls/lvcd/lvcd-image3.jpeg';
import lvcd4 from '../images/service-category/controls/lvcd/lvcd-image4.jpeg';
import mcc1 from '../images/service-category/controls/mcc/mcc-image1.jpg';
import mcc2 from '../images/service-category/controls/mcc/mcc-image2.jpg';
import mcc3 from '../images/service-category/controls/mcc/mcc-image3.jpg';
import pfc1 from '../images/service-category/controls/pfc/pfc-image1.jpg';
import pfc2 from '../images/service-category/controls/pfc/pfc-image2.jpg';
import fi1 from '../images/service-category/instrumentation/fi/f-image1.jpg';
import fi2 from '../images/service-category/instrumentation/fi/f-image2.jpg';
import fi3 from '../images/service-category/instrumentation/fi/f-image3.jpg';
import li1 from '../images/service-category/instrumentation/li/li-image1.jpg';
import li2 from '../images/service-category/instrumentation/li/li-image2.jpg';
import li3 from '../images/service-category/instrumentation/li/li-image3.jpg';
import pi1 from '../images/service-category/instrumentation/pi/pi-image1.jpg';
import pi2 from '../images/service-category/instrumentation/pi/pi-image2.jpg';
import pi3 from '../images/service-category/instrumentation/pi/pi-image3.jpg';
import ti1 from '../images/service-category/instrumentation/ti/ti-image1.jpg';
import ti2 from '../images/service-category/instrumentation/ti/ti-image2.jpg';
import ti3 from '../images/service-category/instrumentation/ti/ti-image3.jpg';

const useStyles = makeStyles((theme) => ({
  buttonContent: {
    height: '52px',
    width: '134px',
    color: 'white',
    paddingTop: 14,
    paddingBottom: 14,
    paddingRight: 20,
    paddingLeft: 20,
    borderRadius: 0,
    fontSize: 12,
    background: '#fe6600',
    transition: '0.5s',
    '&:hover': {
      background: '#0e92d0',
    },
  },
  image: {
    width: '25vw',

    maxHeight: '400px',
    objectFit: 'contain',
    [theme.breakpoints.down('sm')]: {
      width: '60vw',
    },
  },
  imageContainer: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
  },
  imageCaption: {},
  imageContent: {
    display: 'flex',
    justifyContent: 'center',
  },
}));
const Services = () => {
  const classes = useStyles();
  const location = useLocation();
  let history = useHistory();
  //const section = location.pathname.split("/service-category/description/")[1];
  const [link, setLink] = useState('');
  useEffect(() => {
    try {
      if (location.pathname.split('solutions/')[1] !== undefined) {
        switch (decode(location.pathname.split('solutions/')[1])) {
          case 'PLC & HMI':
            setLink('PLC & HMI');

            break;
          case 'Variable Frequency Drives':
            setLink('Variable Frequency Drives');
            break;
          case 'SCADA':
            setLink('SCADA');
            break;
          case 'Remote Monitoring':
            setLink('Remote Monitoring');
            break;
          case 'Automated School Bell':
            setLink('Automated School Bell');
            break;
          case 'PUMP Monitoring System':
            setLink('PUMP Monitoring System');
            break;
          case 'Low voltage Switchboard and distribution boards':
            setLink('Low voltage Switchboard and distribution boards');
            break;
          case 'Industrial Wireless Communication':
            setLink('Industrial Wireless Communication');
            break;
          case 'Motor Control Centre':
            setLink('Motor Control Centre');
            break;
          case 'Automatic Power Factor Correction Panels (APFC)':
            setLink('Automatic Power Factor Correction Panels (APFC)');
            break;
          case 'Automatic mains failure (AMF) panel':
            setLink('Automatic mains failure (AMF) panel');
            break;
          case 'Instrumentation Section':
            setLink('Instrumentation Section');
            break;
          case 'Sensors':
            setLink('Sensors');
            break;
          case 'Testing & Test Equipment':
            setLink('Testing & Test Equipment');
            break;
          case 'Consulting':
            setLink('Consulting');
            break;
          case 'Pressure Instrumentation':
            setLink('Pressure Instrumentation');
            break;
          case 'Temperature instrumentation':
            setLink('Temperature instrumentation');
            break;
          case 'Flow Instrumentation':
            setLink('Flow Instrumentation');
            break;
          case 'Level Instrumentation':
            setLink('Level Instrumentation');
            break;

          default:
            break;
        }
      } else {
        console.log('It is an invalid page');
        //throw "Invalid Page";
      }
    } catch (error) {
      console.log(error);
      //history.push("/");
    }
  }, [link]);

  return (
    <>
      <TopBar />
      <Divider style={{ width: '100 %' }} />
      <MenuBar />
      <Divider style={{ width: '100 %' }} />
      <TopTitle />
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',

          width: '100%',
        }}
      >
        {link !== '' && description(link, classes)}

        <div
          style={{ paddingRight: '5%', paddingLeft: '5%', paddingBottom: '5%' }}
        >
          <Link to='/contact' style={{ textDecoration: 'none' }}>
            <Button variant='contained' className={classes.buttonContent}>
              <strong>GET A QUOTE</strong>
            </Button>
          </Link>
        </div>
      </div>
      <Content />

      <Footer />
    </>
  );
};
function description(value, classes) {
  console.log(value.toString());
  switch (value) {
    case 'PLC & HMI':
      return (
        <div style={{ padding: '5%' }}>
          <Typography style={{ color: '#222222' }}>
            Programmable Logic Controllers – PLC – offer businesses the
            opportunity to truly customize their mechanical processes. Serving
            as the “brain” of the control system, PLC delivers automated
            solutions by initiating operation based on a system of inputs and
            outputs. Human Machine Interfaces – HMI – is designed with the end
            user in mind. This cutting-edge control system allows the operator
            to control the machine and receive feedback from the PLC.<br></br>
            <br></br>
            At Flexpower, we provide you with PLC and HMI programming services
            for small and medium size projects, We can design, build, program,
            test, deliver and install an extensive range of control system
            panels using the latest PLCs and HMI hardware and software.
            <br></br> <br></br> From designing your system to the most specific
            of requirements, Flexpower Automation can program your machine/plant
            to operate autonomously. We give you complete control of your
            process and allow you to monitor operation and log data. We can
            incorporate HMI screens to allow you to change processes or adjust
            individual sections of the operation in order to make your
            plant/machine operate efficiently and cost effectively.<br></br>
            <br></br> We have a broad experience on Siemens{' '}
            <b>
              S7 PLCs and Logo; Allen Bradley PLCs; Mitsubishi HMIs; Mitsubishi
              PLCs ,Omron PLCs; Proface HMIs;Schneider HMIs; Schneider Modicon
              PLCs and Zelio; Siemens HMI – WINCC;{' '}
            </b>
          </Typography>
          <div className={classes.imageContent}>
            <div className={classes.imageContainer}>
              <img
                src={plc1}
                className={classes.image}
                style={{ maxWidth: '90%' }}
                alt='PLC'
              />
              <Typography className={classes.imageCaption} variant='caption'>
                OMRON PLC
              </Typography>
            </div>
            <div className={classes.imageContainer}>
              <img
                src={plc2}
                className={classes.image}
                style={{ maxWidth: '90%' }}
                alt='PLC'
              />
              <Typography className={classes.imageCaption} variant='caption'>
                SIEMENS PLCs and HMI
              </Typography>
            </div>
            <div className={classes.imageContainer}>
              <img
                src={plc3}
                className={classes.image}
                style={{ maxWidth: '90%' }}
                alt='PLC'
              />
              <Typography className={classes.imageCaption} variant='caption'>
                MITSUBISHI PLC
              </Typography>
            </div>
          </div>
        </div>
      );
    case 'Variable Frequency Drives':
      return (
        <div style={{ padding: '5%' }}>
          <Typography style={{ color: '#222222', marginBottom: '2vh' }}>
            Using an AC inverter drive is the most efficient way to control an
            electric 3 phase AC induction motor. It offers speed and torque
            control of the electric AC induction motor to match the process it
            is to be used for, thus not wasting energy, improving productivity
            and reducing maintenance costs which is good for the business and
            environment. electrical mains either single phase or three phase
            application. <br></br>
            <br></br>At Flexpower Automation we can design, build, test,
            deliver,install and commission an automated motion control system
            controlling and monitoring machine moving parts in a safe controlled
            manner. It could incorporate open or closed loop motion control
            commands through an energy amplifier to operate actuators and the
            like, it could be used for{' '}
            <b>
              {' '}
              fan control, stepper motor control, hydraulic pumps, pneumatic
              cylinders{' '}
            </b>{' '}
            etc.<br></br>
            <br></br> We are experts in Siemens Sinamics drives and can
            comfortably commission and provide after sales services.
          </Typography>
          <Typography style={{ color: '#222222', marginBottom: '2vh' }}>
            <strong>This may involve</strong>
          </Typography>

          <ul>
            <li>
              <Typography style={{ color: '#222222' }}>
                New Control System Design and Installation
              </Typography>
            </li>
            <li>
              <Typography style={{ color: '#222222' }}>
                Industrial Panel Design, Layout, Maintenance
              </Typography>
            </li>
            <li>
              <Typography style={{ color: '#222222' }}>
                PLC Programming
              </Typography>
            </li>
            <li>
              <Typography style={{ color: '#222222' }}>
                Install or Update Industrial Control Systems.
              </Typography>
            </li>
            <li>
              <Typography style={{ color: '#222222' }}>
                Communication Networking.
              </Typography>
            </li>
          </ul>

          <div className={classes.imageContent}>
            <div className={classes.imageContainer}>
              <img
                src={vfd1}
                className={classes.image}
                style={{ maxWidth: '90%' }}
                alt='Variable Frequency Drives'
              />
              <Typography className={classes.imageCaption} variant='caption'>
                Danfoss Drive
              </Typography>
            </div>
            <div className={classes.imageContainer}>
              <img
                src={vfd2}
                className={classes.image}
                style={{ maxWidth: '90%' }}
                alt='Variable Frequency Drives'
              />
              <Typography className={classes.imageCaption} variant='caption'>
                Siemens V20 Drive
              </Typography>
            </div>
            <div className={classes.imageContainer}>
              <img
                src={vfd3}
                className={classes.image}
                style={{ maxWidth: '90%' }}
                alt='Variable Frequency Drives'
              />
              <Typography className={classes.imageCaption} variant='caption'>
                Mitsubishi D700 Drive
              </Typography>
            </div>
          </div>
          <Typography style={{ color: '#222222', marginBottom: '2vh' }}>
            We are very proud of our control systems and are happy to provide
            support and updates if and when required.
          </Typography>
        </div>
      );

    case 'SCADA':
      return (
        <div style={{ padding: '3%' }}>
          <Typography style={{ color: '#222222' }}>
            SCADA stands for “Supervisory Control and Data Acquisition”. SCADA
            is a type of process control system architecture that uses
            computers, networked data communications and graphical Human Machine
            Interfaces (HMIs) to enable a high-level process supervisory
            management and control.<br></br>
            <br></br> SCADA systems communicate with other devices such as
            programmable logic controllers (PLCs) and PID controllers to
            interact with industrial process plant and equipment. SCADA systems
            form a large part of control systems engineering.<br></br>
            <br></br> SCADA systems gather pieces of information and data from a
            process that is analysed in real-time. It records and logs the data,
            as well as representing the collected data on various HMIs.<br></br>
            <br></br> At Flexpower, our SCADA solution is Flexible and easy and
            user friendly enabling your process control operators to easily
            supervise what is going on in the field, even from a distant
            location. It also enables operators to control these processes by
            interacting with the HMI.
          </Typography>
          <div
            style={{ width: '100%', display: 'flex' }}
            className={classes.imageContent}
          >
            <div className={classes.imageContainer}>
              <img
                src={scada1}
                className={classes.image}
                style={{ maxWidth: '90%' }}
                alt='SCADA'
              />
              <Typography className={classes.imageCaption} variant='caption'>
                {' '}
                Process Control System (SCADA)
              </Typography>
            </div>
            <div className={classes.imageContainer}>
              <img
                src={scada2}
                className={classes.image}
                style={{ maxWidth: '90%' }}
                alt='SCADA'
              />
              <Typography
                className={classes.imageCaption}
                variant='caption'
              ></Typography>
            </div>
          </div>
        </div>
      );
    case 'Remote Monitoring':
      return (
        <div style={{ padding: '5%' }}>
          <Typography style={{ color: '#222222', marginBottom: '2vh' }}>
            In a plant, it is always important Keep an eye on your system
            parameters at all times and respond accordingly to any deviations.
            Remote monitoring has become part of modern system which ensure
            flexibility and fast response to system conditions.<br></br>
            <br></br> At Flexpower, we Log, follow, and monitor the processes of
            individual modules or entire system parts using our products and
            solutions for monitoring and diagnostics. We offer you with various
            Remote Monitoring solutions from Phoenix Contact for your plant,
            Photovoltaic Parks and many other customizable solutions. This
            enables you to Log, follow, and monitor the processes of individual
            modules or entire system.<br></br>
            <br></br> Our Plant Monitor is a scalable complete solution for
            monitoring production sites. Monitoring the pumping station includes
            recording digital input signals, e.g., valve positions, as well as
            recording analog signals for temperature, pressure, and flow rate
            measurement. To transmit the signals from the ex zone, the signals
            can be forwarded to the Radioline wireless system via MACX signal
            isolators.
          </Typography>

          <div className={classes.imageContainer}>
            <img
              src={remote}
              style={{ maxWidth: '90%' }}
              alt='Remote monitoring'
            />
            <Typography className={classes.imageCaption} variant='caption'>
              Remote Monitoring Solution
            </Typography>
          </div>
        </div>
      );
    case 'Industrial Wireless Communication':
      return (
        <div style={{ padding: '5%' }}>
          <Typography style={{ color: '#222222', marginBottom: '2vh' }}>
            Industrial wireless systems ensure greater flexibility and
            efficiency in the industrial communication of data and signals.
            Flexpower provides you with the required reliable and secure
            communication for monitoring and controlling your remote station
            without cable access products, solutions, and services.<br></br>
            <br></br> With the support of Radioline wireless from Phoenix
            Contact, you can transmit your digital & analogue signals to
            stations 20Km away, and 250 different stations. Radioline transmits
            I/O signals as well as serial data and is therefore very versatile.
          </Typography>

          <ul>
            <li>
              <Typography style={{ color: '#222222' }}>
                Level monitoring
              </Typography>
            </li>
            <li>
              <Typography style={{ color: '#222222' }}>
                Temperature monitoring
              </Typography>
            </li>
            <li>
              <Typography style={{ color: '#222222' }}>
                Flow measurements
              </Typography>
            </li>
            <li>
              <Typography style={{ color: '#222222' }}>
                Pumping stations and well point systems
              </Typography>
            </li>
            <li>
              <Typography style={{ color: '#222222' }}>
                Leak monitoring
              </Typography>
            </li>
            <li>
              <Typography style={{ color: '#222222' }}>
                Infrastructure in chemical parks
              </Typography>
            </li>
            <li>
              <Typography style={{ color: '#222222' }}>
                Water treatment plants
              </Typography>
            </li>
            <li>
              <Typography style={{ color: '#222222' }}>
                Rotating parts (e.g., in scraper bridges)
              </Typography>
            </li>
          </ul>
          <div className={classes.imageContent}>
            <div className={classes.imageContainer}>
              <img
                src={iwc2}
                className={classes.image}
                style={{ maxWidth: '90%' }}
                alt='Variable Frequency Drives'
              />
              <Typography className={classes.imageCaption} variant='caption'>
                {' '}
              </Typography>
            </div>
            <div className={classes.imageContainer}>
              <img
                src={iwc3}
                className={classes.image}
                style={{ maxWidth: '90%' }}
                alt='Variable Frequency Drives'
              />
              <Typography
                className={classes.imageCaption}
                variant='caption'
              ></Typography>
            </div>
            <div className={classes.imageContainer}>
              <img
                src={iwc4}
                className={classes.image}
                style={{ maxWidth: '90%' }}
                alt='Variable Frequency Drives'
              />
              <Typography className={classes.imageCaption} variant='caption'>
                Radioline Wireless Solution for Water and Sewerage sector{' '}
              </Typography>
            </div>
          </div>
        </div>
      );
    case 'PUMP Monitoring System':
      return (
        <div style={{ padding: '5%' }}>
          <Typography style={{ color: '#222222', marginBottom: '2vh' }}>
            A large percentage of unplanned failures are caused by machinery and
            rotating equipment. Often these elements are either maintained in
            fixed time frames or not at all. Where maintenance is performed, it
            is carried out too soon or in some instances too late, thereby
            resulting in production stoppages.<br></br>
            <br></br> When you permanently Monitoring pumps & Motors, the
            operating values will be recorded using the active electrical power
            by means of current and voltage measurement. This value will show
            you the progress, state, and operating point of the machine or
            process. Using this analysis, our pump monitoring solution will help
            you identify faults by means of the active power characteristic,
            enabling maintenance to be performed at the right time.
          </Typography>
          <div className={classes.imageContent}>
            <div className={classes.imageContainer}>
              <img
                src={pms2}
                className={classes.image}
                style={{ maxWidth: '90%' }}
                alt='Pump monitoring system'
              />
              <Typography className={classes.imageCaption} variant='caption'>
                Complete Solution for Monitoring Field Pumps
              </Typography>
            </div>
          </div>
        </div>
      );
    case 'Automated School Bell':
      return (
        <div style={{ padding: '5%' }}>
          <Typography style={{ color: '#222222', marginBottom: '2vh' }}>
            An Automated bell solution is a system that is synchronized with
            your school time table and ensures that the bell goes without delay
            to enhance the swift transition from one program to the other. Other
            than just ringing, This solution will give a ringing pattern is
            customizable to suit different occasions ranging from class time,
            assembly time, and break time.<br></br>
            <br></br> As Flexpower are flexible and able to on your need and
            therefore this solution is customized according to your
            requirements. The system has added extra features such as backup
            features that will ensure the automated electrical bell rings during
            blackouts. Another Special feature is one that will enable you to
            ring the bell to assemble the students from wherever you are via a
            mobile phone
          </Typography>
          <div className={classes.imageContent}>
            <div className={classes.imageContainer}>
              <img
                src={asb1}
                className={classes.image}
                style={{ maxWidth: '90%' }}
                alt='Variable Frequency Drives'
              />
              <Typography
                className={classes.imageCaption}
                variant='caption'
              ></Typography>
            </div>
            <div className={classes.imageContainer}>
              <img
                src={asb2}
                className={classes.image}
                style={{ maxWidth: '90%' }}
                alt='Variable Frequency Drives'
              />
              <Typography
                className={classes.imageCaption}
                variant='caption'
              ></Typography>
            </div>
            <div className={classes.imageContainer}>
              <img
                src={asb3}
                className={classes.image}
                style={{ maxWidth: '90%' }}
                alt='Variable Frequency Drives'
              />
            </div>
          </div>
        </div>
      );
    case 'Motor Control Centre':
      return (
        <div style={{ padding: '5%' }}>
          <Typography style={{ color: '#222222', marginBottom: '2vh' }}>
            MCCs provide the most suitable method for grouping electrical motor
            control, automation and power distribution in a compact economical
            package. Essentially, they are an assembly to control electrical
            motors.<br></br>
            <br></br> Flexpower Automation is known for designing, manufacturing
            and supplying precision engineered Industrial MCC Panels. We are
            experts in perfection. We design and manufacture MCCs that have the
            capacity to feed larger loads, designed as per clients’
            specifications and complying to international standards.<br></br>
            <br></br> In order to start the motor in a safe way, we reduce the
            heavy starting current and also provide overload as well as
            under-voltage protection. Some commonly used starters are:
          </Typography>

          <ul>
            <li>
              <Typography style={{ color: '#222222' }}>
                Direct On-Line.
              </Typography>
            </li>
            <li>
              <Typography style={{ color: '#222222' }}>Star-Delta.</Typography>
            </li>
            <li>
              <Typography style={{ color: '#222222' }}>
                Soft Starter.
              </Typography>
            </li>
            <li>
              <Typography style={{ color: '#222222' }}>
                Variable Frequency Drive (or VFD).
              </Typography>
            </li>
          </ul>
          <div className={classes.imageContent}>
            <div className={classes.imageContainer}>
              <img
                src={mcc3}
                className={classes.image}
                style={{ maxWidth: '90%' }}
                alt='Motor Control Centre'
              />
              <Typography className={classes.imageCaption} variant='caption'>
                Drive Panel
              </Typography>
            </div>
            <div className={classes.imageContainer}>
              <img
                src={mcc2}
                className={classes.image}
                style={{ maxWidth: '90%' }}
                alt='Motor Control Centre'
              />
              <Typography className={classes.imageCaption} variant='caption'>
                Heater Control Panel
              </Typography>
            </div>
            <div className={classes.imageContainer}>
              <img
                src={mcc1}
                className={classes.image}
                style={{ maxWidth: '90%' }}
                alt='Motor Control Centre'
              />
              <Typography className={classes.imageCaption} variant='caption'>
                Softstarter Panel
              </Typography>
            </div>
          </div>
          <Typography style={{ color: '#222222', marginBottom: '2vh' }}>
            We also use different types of solid-state motor controllers,
            analog/digital metering, feeder, fuse dis-connectors, breakers, and
            other switchgear components while designing the MCC.
          </Typography>
        </div>
      );
    case 'Automatic Power Factor Correction Panels (APFC)':
      return (
        <div style={{ padding: '5%' }}>
          <Typography style={{ color: '#222222', marginBottom: '2vh' }}>
            At Flexpower, We analyze the power consumption of your plant and
            design, fabricate and install or retrofit existing Power Factor
            Control Panel Using Epcos Capacitors and Siemens/Schneider Electric
            Switchgears. Other than that, we can also supply PFC spares such for
            Capacitor, Capacitor Contactors and Fuses
          </Typography>
          <div className={classes.imageContent}>
            <div className={classes.imageContainer}>
              <img
                src={pfc1}
                className={classes.image}
                style={{ maxWidth: '90%' }}
                alt='Automatic Power Factor Correction Panels (APFC)'
              />
              <Typography className={classes.imageCaption} variant='caption'>
                Power Factor Controller Switch Gears
              </Typography>
            </div>
            <div className={classes.imageContainer}>
              <img
                src={pfc2}
                className={classes.image}
                style={{ maxWidth: '90%' }}
                alt='Automatic Power Factor Correction Panels (APFC)'
              />
              <Typography className={classes.imageCaption} variant='caption'>
                Capacitors
              </Typography>
            </div>
          </div>
        </div>
      );
    case 'Automatic mains failure (AMF) panel':
      return (
        <div style={{ padding: '5%' }}>
          <Typography style={{ color: '#222222', marginBottom: '2vh' }}>
            Automatic mains failure (AMF) panels are an automated switching
            device that switches the load to main or stand by generator. During
            the event of main power failure, AMF automatically makes the power
            switch to emergency standby generators. Without AMF panels, stand by
            generators need to be operated manually.<br></br>
            <br></br> At Flexpower, we will gladly design and fabricate you an
            ATS according to your load needs enhancing power availability for
            your equipment avoiding data loss, potential damage to electrical
            equipment, and huge amounts of disruption.<br></br>
            <br></br> When the mains power supply returns, the AMF panel switch
            back to the mains supply and shuts down the running generator after
            a suitable cooling run. Automatic mains failure panels are sometimes
            also called an Automatic Transfer Switch (ATS).
          </Typography>
          <div className={classes.imageContent}>
            <div className={classes.imageContainer}>
              <img
                src={amf1}
                className={classes.image}
                style={{ maxWidth: '90%' }}
                alt='Automatic mains failure (AMF) panel'
              />
              <Typography className={classes.imageCaption} variant='caption'>
                Automatic main failure Panel
              </Typography>
            </div>
            <div className={classes.imageContainer}>
              <img
                src={amf2}
                className={classes.image}
                style={{ maxWidth: '90%' }}
                alt='Automatic mains failure (AMF) panel'
              />
              <Typography className={classes.imageCaption} variant='caption'>
                {' '}
                Inside the Automatic Main Failure Panel
              </Typography>
            </div>
          </div>
        </div>
      );
    case 'Sensors':
      return (
        <div style={{ padding: '5%' }}>
          <Typography style={{ color: '#222222', marginBottom: '2vh' }}>
            Sensors are sophisticated devices that are frequently used to detect
            and respond to electrical or optical signals. A Sensor converts the
            physical parameter (for example: temperature, blood pressure,
            humidity, speed, etc.) into a signal which can be measured
            electrically.<br></br>
            <br></br> With our expertise, we will take you through the criteria
            to choose a sensor suitable for your application and process need
            such as the Accuracy, Environmental condition.<br></br>
            <br></br> We deal in both Industrial process control, measurement
            and automation and in Non-Industrial such as in Aircraft, Medical
            products, Automobiles, Consumer electronics, other type of sensors.
            <br></br>
            <br></br> FlexPower handles the following types of sensors –
          </Typography>

          <ul>
            <li>
              <Typography style={{ color: '#222222' }}>
                Proximity Sensors
              </Typography>
            </li>
            <li>
              <Typography style={{ color: '#222222' }}>
                IR Sensor (Infrared Sensor)
              </Typography>
            </li>
            <li>
              <Typography style={{ color: '#222222' }}>
                Temperature sensors (transmitters, gauges)
              </Typography>
            </li>
            <li>
              <Typography style={{ color: '#222222' }}>
                Pressure sensors (transmitters, gauges, and switches)
              </Typography>
            </li>
            <li>
              <Typography style={{ color: '#222222' }}>
                Level sensors (transmitters, gauges, indicators, switches)
              </Typography>
            </li>
          </ul>
          <Typography style={{ color: '#222222', marginBottom: '2vh' }}>
            Some of the brands we stock includes;
            <b>Siemens, Emerson, ABB, Yokogawa, Honeywell, Ifm among others.</b>
          </Typography>
        </div>
      );
    case 'Testing & Test Equipment':
      return (
        <div style={{ padding: '5%' }}>
          <Typography style={{ color: '#222222', marginBottom: '2vh' }}>
            Testing and test instruments are key to any electronics design,
            development, production, and maintenance activity.<br></br>
            <br></br> Electronics test and test equipment are essential elements
            of electronics and radio. Electronic equipment can develop failures,
            or it may not work properly when it is built. To discover whether a
            circuit is working, and then where the problem is located, it is
            necessary to use various forms of test equipment.<br></br> <br></br>
            The items of test equipment that are commonly found are multimeters,
            analog, and digital (DMM - digital multimeter), oscilloscopes, and
            under some circumstances, more specialized test equipment including
            spectrum analyzers may be used.<br></br>
            <br></br> We will not only be able to provide you with such testing
            services but also provide you with the necessary equipment for your
            required tests.<br></br> <br></br> <b>Fluke</b> and <b>Extech </b>
            are among the brand we support in this field
          </Typography>
        </div>
      );
    case 'Pressure Instrumentation':
      return (
        <div style={{ padding: '5%' }}>
          <Typography style={{ color: '#222222', marginBottom: '2vh' }}>
            Pressure Instrumentation which includes various pressure gauges,
            transmitters, sensors, and transducers that are used to measure and
            display the units of pressure of a fluid or gas. Transducers,
            transmitters, and sensors measure the pressure in pumps, pipes,
            tanks, and other processes and then send a signal to a display,
            controller, HMI, or other device
          </Typography>
          <div className={classes.imageContent}>
            <div className={classes.imageContainer}>
              <img
                src={pi1}
                className={classes.image}
                style={{ maxWidth: '90%' }}
                alt='Pressure Instrumentation'
              />
              <Typography className={classes.imageCaption} variant='caption'>
                Differential pressure transmitter
              </Typography>
            </div>
            <div className={classes.imageContainer}>
              <img
                src={pi2}
                className={classes.image}
                style={{ maxWidth: '90%' }}
                alt='Pressure Instrumentation'
              />
              <Typography className={classes.imageCaption} variant='caption'>
                Pressure gauges{' '}
              </Typography>
            </div>
            <div className={classes.imageContainer}>
              <img
                src={pi3}
                className={classes.image}
                style={{ maxWidth: '90%' }}
                alt='Pressure Instrumentation'
              />
              <Typography className={classes.imageCaption} variant='caption'>
                Pressure Transmitter
              </Typography>
            </div>
          </div>
        </div>
      );
    case 'Temperature instrumentation':
      return (
        <div style={{ padding: '5%' }}>
          <Typography style={{ color: '#222222', marginBottom: '2vh' }}>
            Temperature instrumentation includes a wide range of field
            instruments and sensors used to measure temperature in commercial
            and industrial systems i.e Temperature Controllers, Temperature
            transmitters, Temperature Switches, Thermal imagers etc from WIKA,
            NOSHOK, & TURCK
          </Typography>
          <div className={classes.imageContent}>
            <div className={classes.imageContainer}>
              <img
                src={ti1}
                className={classes.image}
                style={{ maxWidth: '90%' }}
                alt='Temperature instrumentation'
              />
              <Typography className={classes.imageCaption} variant='caption'>
                Temperature Switches
              </Typography>
            </div>
            <div className={classes.imageContainer}>
              <img
                src={ti2}
                className={classes.image}
                style={{ maxWidth: '90%' }}
                alt='Temperature instrumentation'
              />
              <Typography className={classes.imageCaption} variant='caption'>
                Temperature Transmitters
              </Typography>
            </div>
            <div className={classes.imageContainer}>
              <img
                src={ti3}
                className={classes.image}
                style={{ maxWidth: '90%' }}
                alt='Temperature instrumentation'
              />
              <Typography className={classes.imageCaption} variant='caption'>
                Temperature Controllers
              </Typography>
            </div>
          </div>
        </div>
      );
    case 'Level Instrumentation':
      return (
        <div style={{ padding: '5%' }}>
          <Typography style={{ color: '#222222', marginBottom: '2vh' }}>
            Flow Instrumentation involves determining the movement of a fluid or
            gas through a pipe or channel. There are two forms of measurement
            typically used: volume and mass. The devices used to measure the
            mass or volumetric flow rate of a liquid or gas include mechanical
            flow meters, differential pressure-based meters, variable area
            meters, electromagnetic flow meters, thermal mass flow meters, and
            more from KROHNE & WIKA
          </Typography>
          <div className={classes.imageContent}>
            <div className={classes.imageContainer}>
              <img
                src={li1}
                className={classes.image}
                style={{ maxWidth: '90%' }}
                alt='Level Instrumentation'
              />
              <Typography className={classes.imageCaption} variant='caption'>
                Level Transmitter
              </Typography>
            </div>
            <div className={classes.imageContainer}>
              <img
                src={li2}
                className={classes.image}
                style={{ maxWidth: '90%' }}
                alt='Level Instrumentation'
              />
              <Typography className={classes.imageCaption} variant='caption'>
                Vibration level switch with cable Extension
              </Typography>
            </div>
            <div className={classes.imageContainer}>
              <img
                src={li3}
                className={classes.image}
                style={{ maxWidth: '90%' }}
                alt='Level Instrumentation'
              />
              <Typography className={classes.imageCaption} variant='caption'>
                Float Switch
              </Typography>
            </div>
          </div>
        </div>
      );
    case 'Flow Instrumentation':
      return (
        <div style={{ padding: '5%' }}>
          <Typography style={{ color: '#222222', marginBottom: '2vh' }}>
            Level Instrumentation includes a range of level measurement
            equipment including switches, controllers, transducers, and radars.
            These instruments play a vital role in ensuring silos, tanks and
            vessels maintain levels that are within safe operating limits. This
            not only protects equipment but helps prevent environmental and
            safety hazards i.e Ultrasonic sensors, Capacitance Probe Switches,
            Non-Contacting Radar sensors, mechanical Float switches, etc.
          </Typography>
          <div className={classes.imageContent}>
            <div className={classes.imageContainer}>
              <img
                src={fi1}
                className={classes.image}
                style={{ maxWidth: '90%' }}
                alt='Flow Instrumentation'
              />
              <Typography className={classes.imageCaption} variant='caption'>
                {' '}
                Electromagnetic Flowmeters
              </Typography>
            </div>

            <div className={classes.imageContainer}>
              <img
                src={fi2}
                className={classes.image}
                style={{ maxWidth: '90%' }}
                alt='Flow Instrumentation'
              />
              <Typography className={classes.imageCaption} variant='caption'>
                {' '}
                Electronic flow switch
              </Typography>
            </div>
            <div className={classes.imageContainer}>
              <img
                src={fi3}
                className={classes.image}
                style={{ maxWidth: '90%' }}
                alt='Flow Instrumentation'
              />
              <Typography className={classes.imageCaption} variant='caption'>
                {' '}
                Cariolis Mass Flowmeter
              </Typography>
            </div>
          </div>
        </div>
      );
    case 'Low voltage Switchboard and distribution boards':
      return (
        <div style={{ padding: '5%' }}>
          <Typography style={{ color: '#222222', marginBottom: '2vh' }}>
            Low voltage panel is a component of an electrical distribution
            system that divides an electrical power feed into branch circuits
            while providing a protective fuse or circuit breaker for each
            circuit in a common enclosure. In essence, panel boards are used to
            protect against electrical overloads and short circuits while
            distributing electricity throughout a building or facility. From
            circuit breakers and busses to enclosures, panel boards, and
            switchboards, we offer a full range of safe, reliable solutions for
            low-voltage electrical distribution applications. This includes ACB,
            MCCBS, MCBs, Contactors, Soft starters, Motor Drives
          </Typography>
          <div className={classes.imageContent}>
            <div className={classes.imageContainer}>
              <img
                src={lvcd1}
                className={classes.image}
                style={{ maxWidth: '90%' }}
                alt='Low voltage Switchboard and distribution boards'
              />
              <Typography className={classes.imageCaption} variant='caption'>
                MPCB
              </Typography>
            </div>
            <div className={classes.imageContainer}>
              <img
                src={lvcd2}
                className={classes.image}
                style={{ maxWidth: '90%' }}
                alt='Low voltage Switchboard and distribution boards'
              />
              <Typography className={classes.imageCaption} variant='caption'>
                ACB
              </Typography>
            </div>
            <div className={classes.imageContainer}>
              <img
                src={lvcd3}
                className={classes.image}
                style={{ maxWidth: '90%' }}
                alt='Low voltage Switchboard and distribution boards'
              />
              <Typography className={classes.imageCaption} variant='caption'>
                Contactor
              </Typography>
            </div>
            <div className={classes.imageContainer}>
              <img
                src={lvcd4}
                className={classes.image}
                style={{ maxWidth: '90%' }}
                alt='Low voltage Switchboard and distribution boards'
              />
              <Typography className={classes.imageCaption} variant='caption'>
                MCCB
              </Typography>
            </div>
          </div>
        </div>
      );

    case 'Consulting':
      return (
        <div style={{ padding: '5%' }}>
          <Typography style={{ color: '#222222', marginBottom: '2vh' }}>
            Flexpower is led by trained and certified engineers with years of
            experience in the automation and controls field who can guide you
            every step of the way. We have both the technical and professional
            know-how to help you navigate your industrial processes easily and
            break through into the market. <br></br>
            <br></br>
            Our doors are open for individuals and companies to get adequate
            training, consultancy services that address pertinent problems,
            suggest and create solutions that are proven to work. We specialise
            in diagnostics, application design, turnkey engineering projects and
            training.
            <br></br>
            <br></br>
            Here, we shall hold your hand through the tough stuff and prepare
            strategies you can apply for great returns on your investment.
          </Typography>

          <ul>
            <li>
              <Typography style={{ color: '#222222' }}>
                Diagnostics and troubleshooting services
              </Typography>
            </li>
            <li>
              <Typography style={{ color: '#222222' }}>
                Application design.
              </Typography>
            </li>
            <li>
              <Typography style={{ color: '#222222' }}>
                Training-We offer Training on PLC & Drives Programming
              </Typography>
            </li>
            <li>
              <Typography style={{ color: '#222222' }}>
                Turnkey Engineering Projects design .
              </Typography>
            </li>
          </ul>
          <div className={classes.imageContent}>
            <div className={classes.imageContainer}>
              <img
                src={cs1}
                className={classes.image}
                style={{ maxWidth: '90%' }}
                alt='Consulation'
              />
              <Typography className={classes.imageCaption} variant='caption'>
                Training Kit
              </Typography>
            </div>
            ]
          </div>
        </div>
      );
    default:
      return (
        <Typography style={{ color: '#222222' }}>PAGE UNAVAILABLE</Typography>
      );
  }
}

export default Services;
