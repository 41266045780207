import { Grid, Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import RightIcon from "@material-ui/icons/ChevronRight";
import React from "react";
import { Link, useHistory, useLocation } from "react-router-dom";
import titleImage from "../../images/top5.jpg";
import { decode } from "./Content";

const useStyles = makeStyles((theme) => ({
	root: {
		backgroundImage: `url(${titleImage})`,
		backgroundSize: "cover",
		height: "calc(30vh)",
	},
	title1: {
		color: "white",

		textAlign: "left",
		fontWeight: 700,
		letterSpacing: "0.1rem",

		[theme.breakpoints.down("xs")]: {
			fontSize: "28px",
		},
		[theme.breakpoints.up("sm")]: {
			fontSize: "36px",
		},
		[theme.breakpoints.up("md")]: {
			paddingLeft: "0px",
			fontSize: "40px",
		},
	},
	directionText: {
		fontSize: "13px",
		fontFamily: "Roboto",
		color: "white",
		paddingLeft: "calc(2vh)",
		alignItems: "baseline",

		paddingRight: "5vw",
		[theme.breakpoints.up("md")]: {
			alignItems: "center",
		},
	},
	serviceContainer: {
		alignItems: "flex-end",
		paddingLeft: "calc(2vh)",
		[theme.breakpoints.up("md")]: {
			alignItems: "center",
		},
	},
	linkStyling: {
		textDecoration: "none",
		color: "white",
		transition: "0.5s",
		"&:hover": {
			textDecoration: "underline",
		},
	},
}));

const Title = () => {
	const location = useLocation();
	const classes = useStyles();
	let history = useHistory();
	let title = "";
	let position = 0;
	let link = "";

	try {
		if (location.pathname.split("description/")[1] !== undefined) {
			title = `${decode(
				location.pathname.split("description/")[1].toUpperCase()
			)}`;
			position = 1;
			switch (location.pathname.split("description/")[1].toUpperCase()) {
				case "AUTOMATION":
					link = "automation";
					break;
				case "CONTROLS":
					link = "controls";
					break;
				case "INSTRUMENTATION":
					link = "instrumentation";
					break;
				case "CONSULTING":
					link = "consulting";
					break;

				default:
					break;
			}
		} else {
			throw "Invalid Page";
		}
	} catch (error) {
		console.log(error);
		history.push("/");
	}

	return (
		<div>
			<Grid container className={classes.root}>
				<Grid
					className={classes.serviceContainer}
					container
					item
					xs={12}
					md={9}
				>
					<Typography component="h3" variant="h3">
						<strong className={classes.title1}>
							{title} {title !== "CONSULTING" ? "SOLUTIONS" : "SERVICES"}
						</strong>
					</Typography>
				</Grid>
				<Grid className={classes.directionText} item container xs={12} md={3}>
					<div
						style={{
							alignItems: "center",
							display: "flex",
							flexDirection: "row",
						}}
					>
						<Link to="/" className={classes.linkStyling}>
							<div style={{ letterSpacing: "0.5px" }}> HOME</div>
						</Link>

						<RightIcon fontSize="small" />

						<>
							<Link
								to={`/service-category/${link}`}
								className={classes.linkStyling}
							>
								<div style={{ letterSpacing: "0.5px" }}>
									{link.toUpperCase()}
								</div>
							</Link>
						</>
					</div>
				</Grid>
			</Grid>
		</div>
	);
};

export default Title;
