import React from 'react';
import { Grid, Typography, Hidden, Link } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import CallIcon from '@material-ui/icons/Call';

import AccessTimeIcon from '@material-ui/icons/AccessTime';
import FacebookIcon from '@material-ui/icons/Facebook';
import TwitterIcon from '@material-ui/icons/Twitter';
import LinkedInIcon from '@material-ui/icons/LinkedIn';
import YouTubeIcon from '@material-ui/icons/YouTube';
import Location from '@material-ui/icons/LocationOn';
import { my_strings } from '../values/strings';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    height: 'calc(100vh*0.07)',
  },
  topFont: {
    fontSize: '14px',
    color: '#888',
    '&:hover': {
      color: '#0e92d0',
    },
  },
  iconFont: {
    color: '#0e92d0',
    fontSize: '18px',
    marginRight: 5,
  },
}));

const TopBar = () => {
  const classes = useStyles();
  return (
    <Hidden smDown>
      <div className={classes.root}>
        <Grid container alignItems='center'>
          <Grid container item xs={9}>
            <Grid container alignItems='center' xs='12'>
              <Grid
                alignItems='center'
                item
                xs='4'
                style={{
                  display: 'flex',
                  paddingLeft: '5%',
                }}
              >
                <CallIcon className={classes.iconFont} />
                <Typography
                  className={classes.topFont}
                  variant='body1'
                  component='p'
                >
                  <div style={{ display: 'flex', flexDirection: 'row' }}>
                    <Link
                      href={`tel:${my_strings.contact.phonenumber}`}
                      style={{ color: '#888', textDecoration: 'none' }}
                    >
                      {my_strings.contact.phonenumber}
                    </Link>
                  </div>
                </Typography>
              </Grid>

              <Grid
                container
                alignContent='center'
                alignItems='center'
                item
                xs='2'
                style={{ display: 'flex' }}
              >
                <AccessTimeIcon className={classes.iconFont} />
                <Typography
                  className={classes.topFont}
                  variant='body1'
                  component='p'
                >
                  Mon-Fri : 8am-5pm
                </Typography>
              </Grid>
              <Grid
                container
                alignContent='center'
                alignItems='center'
                item
                xs='5'
                style={{ display: 'flex' }}
              >
                <Location className={classes.iconFont} />
                <Typography
                  className={classes.topFont}
                  variant='body1'
                  component='p'
                >
             3rd Floor,Winstonia Business Centre.
                </Typography>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={3}>
            <Grid container xs='12'>
              <Grid
                alignContent='right'
                style={{ textAlign: 'right', paddingRight: 20 }}
                item
                xs={4}
              >
                <Link to='/contact' style={{ textDecoration: 'none' }}>
                  <Typography
                    className={classes.topFont}
                    variant='body1'
                    component='p'
                  >
                    Contact Us
                  </Typography>
                </Link>
              </Grid>
              <Grid item xs={8}>
                <Grid
                  style={{ justifyContent: 'space-evenly' }}
                  container
                  xs='12'
                >
                  <FacebookIcon className={classes.iconFont} />

                  <TwitterIcon className={classes.iconFont} />

                  <LinkedInIcon className={classes.iconFont} />

                  <YouTubeIcon className={classes.iconFont} />
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </div>
    </Hidden>
  );
};

export default TopBar;
