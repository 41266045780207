import { Typography, useMediaQuery } from "@material-ui/core";
import Divider from "@material-ui/core/Divider";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import SwipeableDrawer from "@material-ui/core/SwipeableDrawer";
import CloseIcon from "@material-ui/icons/Close";
import clsx from "clsx";
import React from "react";
import { Link, useLocation } from "react-router-dom";
import Logo from "../images/icon.jpg";
import MenuImage from "../images/menu2.png";
const useStyles = makeStyles({
	brand: {
		height: "calc(15vh)",
		display: "inline-flex",
		width: "100%",
		alignContent: "center",
		alignItems: "center",
		backgroundColor: "#ffffff",
	},
	list: {
		width: "calc(100vw)",
		height: "calc(100vh)",
		backgroundColor: "#212121",
	},
	fullList: {
		width: "calc(100vw)",
	},
	drawer: {},
	icon: {
		position: "absolute",
		fontSize: 35,
		color: "#6d6d6d",
		right: 10,
	},
	iconMenu: { display: "flex", justifyContent: "center" },
	containerRoot: {
		display: "flex",
		flexDirection: "column",
		color: "#052451",

		justifyContent: "center",
		alignItems: "center",
		fontSize: 9,
		fontStyle: "bold",
	},
});

export default function SwipeableTemporaryDrawer() {
	const classes = useStyles();
	let location = useLocation();
	const theme = useTheme();
	const matches = useMediaQuery(theme.breakpoints.down("md"));
	const [state, setState] = React.useState({
		left: false,
	});
	const links = ["/", "/solutions", "/about", "/contact"];

	const toggleDrawer = (anchor, open) => (event) => {
		if (
			event &&
			event.type === "keydown" &&
			(event.key === "Tab" || event.key === "Shift")
		) {
			return;
		}

		setState({ ...state, [anchor]: open });
	};
	const closeDrawer = () => {
		setState({ ...state, left: "false" });
	};

	const list = (anchor) => (
		<div
			className={clsx(classes.list, {
				[classes.fullList]: anchor === "top" || anchor === "bottom",
			})}
			role="presentation"
			onClick={toggleDrawer(anchor, false)}
			onKeyDown={toggleDrawer(anchor, false)}
		>
			<div className={classes.brand}>
				<strong className={classes.containerRoot}>
					<img
						src={Logo}
						alt="logo"
						style={{
							objectFit: "contain",
							width: "40%",
						}}
					/>
				</strong>

				<CloseIcon className={classes.icon} onClick={closeDrawer} />
			</div>

			<List>
				{["Home", "Solutions", "About Us", "Contact Us"].map((text, index) => (
					<div style={{ color: "#f5f5f5", padding: "3%" }}>
						<Link
							style={{
								color: `${
									location.pathname === links[index] ? "#fe6600" : "#bdbdbd"
								}`,
								textDecoration: "none",
							}}
							to={links[index]}
							className={classes.footerText}
						>
							<ListItem button key={text}>
								<Typography variant="h6" component="h6">
									{text}
								</Typography>
							</ListItem>
						</Link>
						{index !== 3 && <Divider style={{ backgroundColor: "#484848" }} />}
					</div>
				))}
			</List>
		</div>
	);

	return (
		<div>
			{["left"].map((anchor) => (
				<React.Fragment key={anchor}>
					<Divider orientation="vertical" flexItem />
					<div style={{ marginRight: 5 }} className={classes.iconMenu}>
						<img
							alt="menu"
							src={MenuImage}
							onClick={toggleDrawer(anchor, true)}
							style={{ width: "30px", color: "#212121", right: 10 }}
						/>
					</div>

					<SwipeableDrawer
						anchor={anchor}
						open={state[anchor]}
						onClose={toggleDrawer(anchor, false)}
						onOpen={toggleDrawer(anchor, true)}
					>
						{list(anchor)}
					</SwipeableDrawer>
				</React.Fragment>
			))}
		</div>
	);
}
