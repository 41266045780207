import { Grid, Typography, useMediaQuery } from "@material-ui/core";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import FacebookIcon from "@material-ui/icons/Facebook";
import LinkedInIcon from "@material-ui/icons/LinkedIn";
import TwitterIcon from "@material-ui/icons/Twitter";
import YouTubeIcon from "@material-ui/icons/YouTube";
import React from "react";
import { Link } from "react-router-dom";
import Logo from "../images/icon.jpg";


const useStyles = makeStyles((theme) => ({
	root: {
		backgroundColor: "#212121",
	},
	container: {
		height: "calc(100vh/5)",
		textAlign: "center",
		alignItems: "center",
		justifyContent: "center",
	},
	containerFooter: {},
	containerInnerFooter: {
		justifyContent: "center",
		padding: "calc(5vw)",
	},
	iconFont: {
		color: "#8d8d8d",
		paddingRight: 10,
	},
	footerText: {
		fontSize: 13.5,
	},
}));
const Footer = () => {
	const classes = useStyles();
	const preventDefault = () => {};
	const theme = useTheme();
	const matches = useMediaQuery(theme.breakpoints.down("md"));
	return (
		<div className={classes.root}>
			<Grid container className={classes.containerFooter}>
				<Grid
					container
					className={classes.containerInnerFooter}
					direction="column"
					item
					xs="12"
					sm="6"
				>
					<img
						src={Logo}
						alt="logo"
						style={{
							objectFit: "contain",

							width: `${matches ? "50%" : "40%"}`,
						}}
					/>
					<Typography
						variant="body1"
						component="p"
						className={classes.footerText}
						style={{ color: "#8d8d8d", paddingTop: "2rem" }}
					>
						At Flexpower Automation & Controls we are heavily focused on
						producing high quality, flexible, and reliable solutions for our
						customers.
					</Typography>
					<div
						style={{
							display: "flex",
							width: "100%",
							marginTop: "2rem",
							alignContent: "center",
						}}
					>
						<FacebookIcon className={classes.iconFont} />

						<TwitterIcon className={classes.iconFont} />

						<LinkedInIcon className={classes.iconFont} />

						<YouTubeIcon className={classes.iconFont} />
					</div>
				</Grid>
				<Grid
					container
					className={classes.containerInnerFooter}
					direction="column"
					item
					xs="12"
					sm="3"
				>
					<Typography
						variant="body1"
						component="p"
						className={classes.footerText}
						style={{ color: "#ffffff" }}
					>
						<strong>USEFUL LINKS</strong>
					</Typography>
					<Typography
						variant="body1"
						component="p"
						className={classes.footerText}
						style={{ color: "#8d8d8d", paddingTop: "1rem" }}
					>
						<Link
							style={{ color: "#8d8d8d", textDecoration: "none" }}
							to="/"
							onClick={preventDefault}
						>
							Home
						</Link>
					</Typography>
					<Typography
						variant="body1"
						component="p"
						className={classes.footerText}
						style={{ color: "#8d8d8d", paddingTop: "1rem" }}
					>
						<Link
							style={{ color: "#8d8d8d", textDecoration: "none" }}
							to="solutions"
							onClick={preventDefault}
						>
							Solutions
						</Link>
					</Typography>
					<Typography
						variant="body1"
						component="p"
						className={classes.footerText}
						style={{ color: "#8d8d8d", paddingTop: "1rem" }}
					>
						<Link
							style={{ color: "#8d8d8d", textDecoration: "none" }}
							to="/about"
							onClick={preventDefault}
						>
							About Us
						</Link>
					</Typography>
					<Typography
						variant="body1"
						component="p"
						className={classes.footerText}
						style={{ color: "#8d8d8d", paddingTop: "1rem" }}
					>
						<Link
							style={{ color: "#8d8d8d", textDecoration: "none" }}
							to="/contact"
							onClick={preventDefault}
						>
							Contact Us
						</Link>
					</Typography>
				</Grid>
				<Grid
					className={classes.containerInnerFooter}
					style={{ fontSize: "12" }}
					item
					xs="12"
					sm="3"
				>
					<Typography
						variant="body1"
						component="p"
						className={classes.footerText}
						style={{ color: "#ffffff" }}
					>
						<strong>SOLUTIONS</strong>
					</Typography>
					<Typography
						variant="body1"
						component="p"
						className={classes.footerText}
						style={{ color: "#8d8d8d", paddingTop: "1rem" }}
					>
						<Link
							style={{ color: "#8d8d8d", textDecoration: "none" }}
							to="/service-category/automation"
							className={classes.footerText}
							onClick={preventDefault}
						>
							Automation
						</Link>
					</Typography>
					<Typography
						variant="body1"
						component="p"
						className={classes.footerText}
						style={{ color: "#8d8d8d", paddingTop: "1rem" }}
					>
						<Link
							style={{ color: "#8d8d8d", textDecoration: "none" }}
							to="/service-category/controls"
							onClick={preventDefault}
						>
							Controls
						</Link>
					</Typography>
					<Typography
						variant="body1"
						component="p"
						className={classes.footerText}
						style={{ color: "#8d8d8d", paddingTop: "1rem" }}
					>
						<Link
							style={{ color: "#8d8d8d", textDecoration: "none" }}
							to="/service-category/consultation"
							onClick={preventDefault}
						>
							Consulting
						</Link>
					</Typography>
					<Typography
						variant="body1"
						component="p"
						className={classes.footerText}
						style={{ color: "#8d8d8d", paddingTop: "1rem" }}
					>
						<Link
							style={{ color: "#8d8d8d", textDecoration: "none" }}
							to="/service-category/instrumentation"
							onClick={preventDefault}
						>
							instrumentation
						</Link>
					</Typography>
				</Grid>
			</Grid>
			<Grid container className={classes.containerInnerFooter}>
				<Grid
					style={{ color: "#8d8d8d", textDecoration: "none" }}
					item
					xs="12"
					sm="9"
				>
					© 2021 Flexpower Automation & Controls, Ltd
				</Grid>
				<Grid container direaction="row" item xs="12" sm="3">
					<Typography
						variant="body1"
						component="p"
						className={classes.footerText}
						style={{ color: "#8d8d8d", paddingTop: "1rem" }}
					>
						<Link
							style={{ color: "#8d8d8d", textDecoration: "none" }}
							href="#"
							onClick={preventDefault}
						>
							Privacy Policy .
						</Link>
					</Typography>
					<Typography
						variant="body1"
						component="p"
						className={classes.footerText}
						style={{ color: "#8d8d8d", paddingTop: "1rem" }}
					>
						<Link
							style={{ color: "#8d8d8d", textDecoration: "none" }}
							href="#"
							onClick={preventDefault}
						>
							Terms Of use
						</Link>
					</Typography>
				</Grid>
			</Grid>
		</div>
	);
};

export default Footer;
