import { Grid, Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import React from "react";
import ScrollAnimation from "react-animate-on-scroll";
import { Link } from "react-router-dom";
import arrow_right from "../images/arrow_right.png";
import Automation from "../images/automation.png";
import consultation from "../images/consultation.png";
import controls from "../images/controls.png";
import panel from "../images/panel.png";

const useStyles = makeStyles((theme) => ({
	container: {
		justifyContent: "center",
		textAlign: "center",
		alignContent: "center",
		color: "#0e92d0",
		height: "calc(50vh)",
		marginBottom: "2vh",
	},
	serviceImage: {
		transition: "0.3s",
		"&:hover": {
			borderRadius: "50%",
			border: "4px solid #fe6600",
		},
	},
	root: {},
}));

const Services = () => {
	const classes = useStyles();
	return (
		<div
			id="services"
			className={classes.root}
			style={{ marginLeft: 50, marginRight: 50, marginTop: "5vh" }}
		>
			<Grid
				className="animate__animated animate__slideInUp"
				alignItems="center"
				container
			>
				<Grid
					direction="column"
					className={classes.container}
					item
					md="3"
					sm="6"
					xs="12"
				>
					<ScrollAnimation
						animateOnce="true"
						animateIn="animate__animated animate__slideInUp"
					>
						<div
							style={{
								display: "inline-flex",
								textAlign: "center",
								alignContent: "center",
								justifyContent: "center",
								width: "100%",
							}}
						>
							<div style={{ padding: 20 }} className={classes.serviceImage}>
								<img
									alt="Automation"
									style={{ objectFit: "contain", width: 60 }}
									src={Automation}
								></img>
							</div>
						</div>
						<Typography style={{ marginTop: 20 }} variant="h5" component="h5">
							<strong>Automation</strong>
						</Typography>
						<Typography
							style={{
								marginTop: 20,
								color: "#b1b1b1",
								fontSize: "15px",
							}}
							variant="body1"
							component="p"
						>
							PLC & HMI Programming,Variable Frequency Drive Installation &
							Commission
							<br></br>
							<br></br>
						</Typography>

						<Link
							to="/service-category/automation"
							style={{ color: "#0e92d0", textDecoration: "none" }}
						>
							<div
								style={{
									display: "inline-flex",
									textAlign: "center",
									alignContent: "center",
									justifyContent: "center",
									width: "100%",
								}}
							>
								<Typography
									style={{ fontSize: "13px" }}
									variant="body1"
									component="p"
								>
									<strong>Read More</strong>
								</Typography>
								<img
									alt="read more"
									src={arrow_right}
									style={{ marginLeft: "10px", width: "17px" }}
								/>
							</div>
						</Link>
					</ScrollAnimation>
				</Grid>

				<Grid
					direction="column"
					className={classes.container}
					item
					md="3"
					sm="6"
					xs="12"
				>
					<ScrollAnimation
						animateOnce="true"
						animateIn="animate__animated animate__slideInUp"
					>
						<div
							style={{
								display: "inline-flex",
								textAlign: "center",
								alignContent: "center",
								justifyContent: "center",
								width: "100%",
							}}
						>
							<div style={{ padding: 20 }} className={classes.serviceImage}>
								<img
									alt="Control"
									style={{ objectFit: "contain", width: 60 }}
									src={controls}
								></img>
							</div>
						</div>

						<Typography style={{ marginTop: 20 }} variant="h5" component="h5">
							<strong>Controls</strong>
						</Typography>
						<Typography
							style={{
								marginTop: 20,

								color: "#b1b1b1",
								fontSize: "15px",
							}}
							variant="body1"
							component="p"
						>
							Electrical Design,Product replacements,Retrofits &
							Upgrades,Support and Maintenance.
						</Typography>
						<Link
							to="/service-category/controls"
							style={{ color: "#0e92d0", textDecoration: "none" }}
						>
							<div
								style={{
									display: "inline-flex",
									textAlign: "center",
									alignContent: "center",
									justifyContent: "center",
									width: "100%",
								}}
							>
								<Typography
									style={{ fontSize: "13px" }}
									variant="body1"
									component="p"
								>
									<strong>Read More</strong>
								</Typography>
								<img
									alt="read more"
									src={arrow_right}
									style={{ marginLeft: "10px", width: "17px" }}
								/>
							</div>
						</Link>
					</ScrollAnimation>
				</Grid>

				<Grid
					direction="column"
					className={classes.container}
					item
					md="3"
					sm="6"
					xs="12"
				>
					<ScrollAnimation
						animateOnce="true"
						animateIn="animate__animated animate__slideInUp"
					>
						<div
							style={{
								display: "inline-flex",
								textAlign: "center",
								alignContent: "center",
								justifyContent: "center",
								width: "100%",
							}}
						>
							<div style={{ padding: 20 }} className={classes.serviceImage}>
								<img
									alt="panel"
									style={{ objectFit: "contain", width: 60 }}
									src={panel}
								></img>
							</div>
						</div>

						<Typography style={{ marginTop: 20 }} variant="h5" component="h5">
							<strong>Instrumentation</strong>
						</Typography>
						<Typography
							style={{
								marginTop: 20,
								color: "#b1b1b1",
								fontSize: "15px",
							}}
							variant="body1"
							component="p"
						>
							Automation and instrumentation.<br></br>
							<br></br>
							<br></br>
						</Typography>
						<Link
							to="/service-category/instrumentation"
							style={{ color: "#0e92d0", textDecoration: "none" }}
						>
							<div
								style={{
									display: "inline-flex",
									textAlign: "center",
									alignContent: "center",
									justifyContent: "center",
									width: "100%",
								}}
							>
								<Typography
									style={{ fontSize: "13px" }}
									variant="body1"
									component="p"
								>
									<strong>Read More</strong>
								</Typography>
								<img
									alt="read more"
									src={arrow_right}
									style={{ marginLeft: "10px", width: "17px" }}
								/>
							</div>
						</Link>
					</ScrollAnimation>
				</Grid>

				<Grid
					direction="column"
					className={classes.container}
					item
					md="3"
					sm="6"
					xs="12"
				>
					<ScrollAnimation
						animateOnce="true"
						animateIn="animate__animated animate__slideInUp"
					>
						<div
							style={{
								display: "inline-flex",
								textAlign: "center",
								alignContent: "center",
								justifyContent: "center",
								width: "100%",
							}}
						>
							<div style={{ padding: 20 }} className={classes.serviceImage}>
								<img
									alt="Automation"
									style={{ objectFit: "contain", width: 60 }}
									src={consultation}
								></img>
							</div>
						</div>

						<Typography style={{ marginTop: 20 }} variant="h5" component="h5">
							<strong>Consulting</strong>
						</Typography>
						<Typography
							style={{
								marginTop: 20,
								color: "#b1b1b1",
								fontSize: "15px",
							}}
							variant="body1"
							component="p"
						>
							Need answers before you start a project? We offer consultation
							services.
							<br></br>
							<br></br>
						</Typography>
						<Link
							to="/service-category/consultation"
							style={{ color: "#0e92d0", textDecoration: "none" }}
						>
							<div
								style={{
									display: "inline-flex",
									textAlign: "center",
									alignContent: "center",
									justifyContent: "center",
									width: "100%",
								}}
							>
								<Typography
									style={{ fontSize: "13px" }}
									variant="body1"
									component="p"
								>
									<strong>Read More</strong>
								</Typography>
								<img
									alt="read more"
									src={arrow_right}
									style={{ marginLeft: "10px", width: "17px" }}
								/>
							</div>
						</Link>
					</ScrollAnimation>
				</Grid>
			</Grid>
		</div>
	);
};

export default Services;
