import { Container, Grid, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { ArrowForward } from '@material-ui/icons';
import React, { useEffect, useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import asb1 from '../../images/service-category/automation/asb/asb-image3.jpeg';
import iwc1 from '../../images/service-category/automation/iwc/iwc-image2.jpg';
import plc_main from '../../images/service-category/automation/plc/plc-image1.jpg';
import scada1 from '../../images/service-category/automation/scada/scada-image2.jpg';
import vfd1 from '../../images/service-category/automation/vfd/vfd-image1.jpg';
import rm1 from '../../images/service-category/automation/rm/rm-image1.jpg';
import cs1 from '../../images/service-category/consultation/cs-image2.jpg';
import amf1 from '../../images/service-category/controls/amf/amf-image1.jpg';
import lvcd1 from '../../images/service-category/controls/lvcd/lvcd-image4.jpeg';
import mcc1 from '../../images/service-category/controls/mcc/mcc-image3.jpg';
import pfc1 from '../../images/service-category/controls/pfc/pfc-image1.jpg';
import fi1 from '../../images/service-category/instrumentation/fi/f-image1.jpg';
import pms1 from '../../images/service-category/automation/pms/pms-image2.jpeg';
import li1 from '../../images/service-category/instrumentation/li/li-image2.jpg';
import pi1 from '../../images/service-category/instrumentation/pi/pi-image2.jpg';
import ti1 from '../../images/service-category/instrumentation/ti/ti-image1.jpg';
import { encode } from './Content';

const useStyles = makeStyles((theme) => ({
  item: {
    position: 'relative',
    backgroundSize: 'contain',
    width: '100%',
    height: 'calc(40vh)',
  },
  content: {
    position: 'absolute',
    width: '90%',
    background: 'white',
    top: 'calc(35vh)',
    left: 0,
    right: 0,
    marginLeft: 'auto',
    marginRight: 'auto',
    transition: '0.7s',
    borderBottom: '4px solid #ffffff',
    '&:hover': {
      borderBottom: '4px solid #FE6600',
    },
  },
  content_items: {
    display: 'flex',
    width: '100%',
    margin: '5%',
    flexDirection: 'column',
    textAlign: 'left',
  },
  title: {
    color: '#222222',
    '&:hover': {
      color: '#0E92DO',
    },
  },
}));

const ServiceCategory = () => {
  const [contentToRender, setContent] = useState([]);
  const classes = useStyles();
  const location = useLocation();
  const section = location.pathname.split('/service-category/')[1];
  const subsection = location.pathname.split(
    '/service-category/description/',
  )[1];
  console.log(subsection);
  const controls = [
    {
      image: lvcd1,
      title: 'Low voltage Switchboard and distribution boards',
      link: 'Low voltage Switchboard and distribution boards',
    },
    {
      image: pfc1,
      title: 'Automatic Power Factor Correction Panels (APFC)',
      link: 'Automatic Power Factor Correction Panels (APFC)',
    },
    {
      image: mcc1,
      title: 'Motor Control Centre',
      link: 'Motor Control Centre',
    },
    {
      image: amf1,
      title: 'Automatic mains failure (AMF) panel',
      link: 'Automatic mains failure (AMF) panel',
    },
  ];

  const automation = [
    {
      image: plc_main,
      title: 'Programming of PLC & HMI',
      link: 'PLC & HMI',
    },
    {
      image: scada1,
      title: 'Supervisory Control and Data Acquisition (SCADA)',
      link: 'SCADA',
    },
    {
      image: vfd1,
      title: 'Variable Frequency Drives',
      link: 'Variable Frequency Drives',
    },
    {
      image: iwc1,
      title: 'Industrial Wireless Communication',
      link: 'Industrial Wireless Communication',
    },
    {
      image: pms1,
      title: 'Pump monitoring systems (PMS)',
      link: 'PUMP Monitoring System',
    },
    {
      image: rm1,
      title: 'Remote monitoring systems.',
      link: 'Remote Monitoring',
    },
    {
      image: asb1,
      title: 'Automated School Bell',
      link: 'Automated School Bell',
    },
  ];

  const instrumentation = [
    {
      image: pi1,
      title: 'Pressure Instrumentation',
      link: 'Pressure Instrumentation',
    },
    {
      image: ti1,
      title: 'Temperature instrumentation',
      link: 'Temperature instrumentation',
    },
    {
      image: fi1,
      title: 'Flow Instrumentation',
      link: 'Flow Instrumentation',
    },
    {
      image: li1,
      title: 'Level Instrumentation',
      link: 'Level Instrumentation',
    },
  ];
  const consultation = [
    {
      image: cs1,
      title: 'Training and consultation',
      link: 'Consulting',
    },
  ];

  useEffect(() => {
    if (subsection === undefined) {
      if (section === 'controls') {
        setContent(controls);
        console.log('undefined called ');
      } else if (section === 'automation') {
        setContent(automation);
      } else if (section === 'consultation') {
        setContent(consultation);
      } else if (section === 'instrumentation') {
        setContent(instrumentation);
      }
    } else {
      const sub = subsection.toLowerCase();
      if (sub === 'controls') {
        setContent(controls);
      } else if (sub === 'automation') {
        setContent(automation);
      } else if (sub === 'consulting') {
        setContent(consultation);
      } else if (sub === 'instrumentation') {
        setContent(instrumentation);
      }
    }
  }, []);

  return (
    <Container className={classes.root}>
      <Grid container>
        {contentToRender.length !== 0 &&
          contentToRender.map((item, index) => {
            return (
              <Grid
                style={{ height: 'calc(80vh)', padding: '2%' }}
                item
                xs={12}
                md={4}
              >
                <div
                  className={classes.item}
                  style={{
                    background: `url(${item.image})`,
                    backgroundSize: 'contain',
                    backgroundRepeat: 'no-repeat',
                  }}
                >
                  <div className={classes.content}>
                    <div className={classes.content_items}>
                      <Typography
                        style={{ justifyContent: 'center', margin: '5%' }}
                        component='h5'
                        variant='h5'
                      >
                        <strong className={classes.title}> {item.title}</strong>
                      </Typography>
                      <Link to={`/solutions/${encode(item.link)}`}>
                        <div
                          style={{
                            display: 'flex',
                            direction: 'row',
                            alignItems: 'center',
                            margin: '5%',
                          }}
                        >
                          <Typography
                            style={{
                              justifyContent: 'center',
                              marginRight: '2%',
                            }}
                            component='p'
                            variant='body1'
                          >
                            <strong
                              style={{ fontSize: '13px', color: '#222222' }}
                            >
                              {' '}
                              SEE MORE
                            </strong>
                          </Typography>
                          <ArrowForward
                            style={{ fontSize: '13px', color: '#FE6600' }}
                          />
                        </div>
                      </Link>
                    </div>
                  </div>
                </div>
              </Grid>
            );
          })}
      </Grid>
    </Container>
  );
};

export default ServiceCategory;
