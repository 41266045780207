import React from 'react';
import Carousel from 'react-multi-carousel';
import 'react-multi-carousel/lib/styles.css';
import logo1 from '../../images/logos/lg1.jpg';
import logo2 from '../../images/logos/lg2.jpg';
import logo3 from '../../images/logos/lg3.jpg';
import logo4 from '../../images/logos/lg4.jpg';
import logo5 from '../../images/logos/lg5.jpg';
import logo6 from '../../images/logos/lg6.jpg';
import logo7 from '../../images/logos/lg7.jpg';
import logo8 from '../../images/logos/lg8.jpg';
import logo9 from '../../images/logos/lg9.jpg';

const responsive = {
  desktop: {
    breakpoint: { max: 3000, min: 1024 },
    items: 3,
    slidesToSlide: 3, // optional, default to 1.
  },
  tablet: {
    breakpoint: { max: 1024, min: 464 },
    items: 2,
    slidesToSlide: 2, // optional, default to 1.
  },
  mobile: {
    breakpoint: { max: 464, min: 0 },
    items: 1,
    slidesToSlide: 1, // optional, default to 1.
  },
};

const LogoCarousel = () => {
  const imagestyle = {
    height: '100px',
    margin: '1rem',
    objectFit: 'contain',
  };
  return (
    <div>
      <Carousel
        swipeable={false}
        draggable={false}
        showDots={false}
        responsive={responsive}
        ssr={true} // means to render carousel on server-side.
        infinite={true}
        autoPlay={true}
        autoPlaySpeed={2000}
        keyBoardControl={true}
        customTransition='all .5'
        transitionDuration={500}
        containerClass='carousel-container'
        removeArrowOnDeviceType={['tablet', 'mobile']}
        dotListClass='custom-dot-list-style'
        itemClass='carousel-item-padding-40-px'
      >
        <div>
          <img alt={'company brand logos'} src={logo1} style={imagestyle} />
        </div>
        <div>
          <img alt={'company brand logos'} src={logo2} style={imagestyle} />
        </div>
        <div>
          <img alt={'company brand logos'} src={logo3} style={imagestyle} />
        </div>
        <div>
          <img alt={'company brand logos'} src={logo4} style={imagestyle} />
        </div>
        <div>
          <img alt={'company brand logos'} src={logo5} style={imagestyle} />
        </div>
        <div>
          <img alt={'company brand logos'} src={logo6} style={imagestyle} />
        </div>
        <div>
          <img alt={'company brand logos'} src={logo7} style={imagestyle} />
        </div>
        <div>
          <img alt={'company brand logos'} src={logo8} style={imagestyle} />
        </div>
        <div>
          <img alt={'company brand logos'} src={logo9} style={imagestyle} />
        </div>
      </Carousel>
      ;
    </div>
  );
};

export default LogoCarousel;
