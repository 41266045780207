import {
  Button, Container,
  Grid,



  TextareaAutosize, TextField, Typography
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import React from 'react';
import { my_strings } from '../../values/strings';

const useStyles = makeStyles((theme) => ({
  root: {
    alignContent: 'center',
    justifyContent: 'center',
  },
  buttonContent: {
    color: 'white',
    paddingTop: 14,
    paddingBottom: 14,
    paddingRight: 20,
    paddingLeft: 20,
    borderRadius: 0,
    fontSize: 12,
    background: '#fe6600',
    marginTop: '10vh',
    border: '2px solid #fe6600',
    marginBottom: '10vh',
    transition: '0.5s',
    '&:hover': {
      background: '#212121',
      border: '2px solid #fe6600',
    },
  },
  content: {
    backgroundColor: '#212121',
    marginBottom: '10vh',
  },
  buttonContentMore: {
    paddingTop: 14,
    paddingBottom: 14,
    paddingRight: 20,
    paddingLeft: 20,
    borderRadius: 0,
    marginLeft: '2%',
    fontSize: 12,
    textStyle: 'bold',
    border: '2px solid #bdbdbd',
    color: '#00255b',
    marginTop: '10vh',
    marginBottom: '10vh',
    transition: '0.5s',

    '&:hover': {
      background: '#fe6600',
      color: '#ffffff',
    },
  },
  contentMore: { marginBottom: '10vh' },

  imageStyle: {
    objectFit: 'contain',
    paddingRight: '5%',
    width: '20%',
  },
  textList: {
    color: '#828282',
    marginBottom: '2vh',
  },
  list: {
    paddingBottom: '2vh',
    marginRight: 0,
  },
  inputRoot: {
    fontSize: '15px',
  },
  labelRoot: {
    fontSize: '15px',
    color: '#888888',
    '&$labelFocused': {
      color: '#FE6600',
    },
  },
  labelFocused: {},
}));
const AboutQuote = () => {
  const classes = useStyles();

  return (
    <div className='root'>
      <Container>
        <div
          style={{
            display: 'flex',
            width: '100%',
            justifyContent: 'center',
            marginBottom: 'calc(8vh)',
            color: '#0E92D0',
          }}
        >
          <Typography component='h3' variant='h3'>
            <strong>Call Us or Email</strong>
          </Typography>
        </div>
        <Grid container style={{ paddingBottom: 'calc(5vh)' }}>
          <Grid
            style={{ marginBottom: '10vh', color: '#FE6600' }}
            container
            direction='column'
            item
            sm={6}
            xs='12'
          >
            <TextField
              style={{
                marginBottom: '2vh',
                marginLeft: '2vh',
                marginRight: '2vh',
                fontFamily: 'Roboto',
                fontSize: '15px',
              }}
              id='standard-basic'
              required
              label='Your Name'
              InputProps={{ classes: { root: classes.inputRoot } }}
              InputLabelProps={{
                classes: {
                  root: classes.labelRoot,
                  focused: classes.labelFocused,
                },
              }}
            />
            <TextField
              style={{ margin: '2vh', fontFamily: 'Roboto', fontSize: '15px' }}
              id='standard-basic'
              required
              label='Your Email'
              InputProps={{ classes: { root: classes.inputRoot } }}
              InputLabelProps={{
                classes: {
                  root: classes.labelRoot,
                  focused: classes.labelFocused,
                },
              }}
            />
            <TextField
              style={{ margin: '2vh', fontFamily: 'Roboto', fontSize: '15px' }}
              id='standard-basic'
              label='Phone Number'
              InputProps={{ classes: { root: classes.inputRoot } }}
              InputLabelProps={{
                classes: {
                  root: classes.labelRoot,
                  focused: classes.labelFocused,
                },
              }}
            />
            <Typography
              style={{
                margin: '2vh',
                color: '#888888',
                fontFamily: 'Roboto',
                fontSize: '15px',
              }}
            >
              Shoot us an email and we will get back to you quickly. If you
              cannot reach us by email. Please give us a call at{' '}
              {my_strings.contact.phonenumber} :{' '}
              {my_strings.contact.sales_email}
            </Typography>
          </Grid>
          <Grid
            style={{ marginBottom: '10vh' }}
            container
            direction='row'
            item
            sm={6}
            xs='12'
          >
            <TextareaAutosize
              aria-label='minimum height'
              rowsMin={8}
              style={{
                width: '100%',
                border: '0px',
                borderBottom: '2px solid #DDDDDD',
                fontSize: '15px',
                fontFamily: 'Roboto',
              }}
              placeholder='Your Message'
              label='Your Message'
            />
            <Button variant='outlined' className={classes.buttonContent}>
              <strong>SEND MESSAGE</strong>
            </Button>
          </Grid>
        </Grid>
      </Container>
    </div>
  );
};

export default AboutQuote;
