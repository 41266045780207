import { Button, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import React from 'react';
import ScrollAnimation from 'react-animate-on-scroll';
import { Link } from 'react-router-dom';
import displayAbout from '../images/background2.jpg';
import { my_strings } from '../values/strings';

const useStyles = makeStyles((theme) => ({
  buttonContent: {
    height: '52px',
    width: '134px',
    color: 'white',
    paddingTop: 14,
    paddingBottom: 14,
    paddingRight: 20,
    paddingLeft: 20,
    borderRadius: 0,
    fontSize: 12,
    background: '#fe6600',
    marginTop: 36,
    transition: '0.5s',
    '&:hover': {
      background: '#0e92d0',
    },
  },
  content: {
    height: 'calc(60vh)',
    display: 'flex',
    color: 'white',
    width: '100%',
    justifyContent: 'center',
    alignItems: 'center',
    alignContent: 'center',
    textAlign: 'center',
  },
  root: {
    marginTop: 100,

    backgroundImage: `url(${displayAbout})`,
  },
}));

const SectionQuote = () => {
  const classes = useStyles();
  return (
    <div className={classes.root}>
      <div className={classes.content}>
        <ScrollAnimation
          animateOnce='true'
          animateIn='animate__animated animate__slideInUp'
        >
          <Typography
            variant='h6'
            style={{ marginBottom: '27px' }}
            component='h6'
          >
            <strong>Based in Kenya</strong>
          </Typography>
          <Typography variant='h2' component='h2'>
            <strong>Envision the future...</strong>
          </Typography>
          <Link to='/contact' style={{ textDecoration: 'none' }}>
            <Button variant='contained' className={classes.buttonContent}>
              <strong>GET A QUOTE</strong>
            </Button>
          </Link>
          <div style={{ marginTop: 30 }}>
            <Typography variant='body1' component='body2'>
              <strong>
                or contact us via {my_strings.contact.sales_email}
              </strong>
            </Typography>
          </div>
        </ScrollAnimation>
      </div>
    </div>
  );
};

export default SectionQuote;
