import { Grid, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import LocationOnIcon from '@material-ui/icons/LocationOn';
import PhoneInTalkIcon from '@material-ui/icons/PhoneInTalk';
import React from 'react';
import message from '../images/curvedBackground.png';
import { my_strings } from '../values/strings';


const useStyles = makeStyles((theme) => ({
  root: {
    background: '#0e92d0',
  },
  container: {
    height: 'calc(100vh/5)',
    alignItems: 'center',
    justifyContent: 'center',
  },
  containerFooter: {
    marginTop: 'calc(100vh*0.1)',
  },
  containerInnerFooter: {
    justifyContent: 'center',
    paddingRight: 'calc(5vw)',
    paddingLeft: 'calc(5vw)',
  },
  iconFont: {
    color: '#bdbdbd',
    paddingRight: 10,
  },
  footerText: {
    fontSize: 13.5,
  },
}));
const ContactBlock = () => {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <Grid container>
        <Grid
          className={classes.container}
          style={{
            backgroundImage: `url(${message})`,
            color: '#ffffff',
            backgroundSize: 'cover',
            backgroundRepeat: 'no-repeat',
          }}
          item
          container
          xs='12'
          sm='6'
        >
          <div
            style={{
              display: 'flex',
              flexDirection: 'row',
              alignContent: 'center',
              alignItems: 'center',
            }}
          >
            <LocationOnIcon style={{ fontSize: 40 }} />
            <div>
              <div>
                <Typography variant='body1' component='body1'>
                  <strong>3rd Floor,Winstonia Business Centre.</strong>
                </Typography>
              </div>
              <div>
              <Typography variant='body1' component='body1'>
                  <strong>Airport North Road ,Off Mombasa Road</strong>
                </Typography>
                </div>
              <Typography variant='body1' component='body1'>
                <strong>P.O Box 61094-00200.Nairobi</strong>
              </Typography>
            </div>
          </div>
        </Grid>
        <Grid
          container
          style={{
            background: '#0e92d0',

            color: '#ffffff',
            backgroundRepeat: 'no-repeat',
          }}
          className={classes.container}
          item
          xs='12'
          sm='6'
        >
          <div
            style={{
              display: 'flex',
              flexDirection: 'row',
              alignContent: 'center',
              alignItems: 'center',
            }}
          >
            <PhoneInTalkIcon style={{ fontSize: 40 }} />
            <div style={{ marginLeft: 5 }}>
              <div>
                <Typography variant='body1' component='body1'>
                  <strong>Cell :{my_strings.contact.phonenumber}</strong>
                </Typography>
              </div>
              <Typography variant='body1' component='body1'>
                <strong>Email : {my_strings.contact.sales_email}</strong>
              </Typography>
            </div>
          </div>
        </Grid>
      </Grid>
    </div>
  );
};

export default ContactBlock;
