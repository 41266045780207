import React from "react";
import TopBar from "../components/TopBar";
import MenuBar from "../components/MenuBar";
import TopTitle from "../components/service/Title";
import Content from "../components/service/Content";
import { Divider } from "@material-ui/core";
import Footer from "../components/Footer";
const Services = () => {
	return (
		<>
			<TopBar />
			<Divider style={{ width: "100 %" }} />
			<MenuBar />
			<Divider style={{ width: "100 %" }} />
			<TopTitle />
			<Content />

			<Footer />
		</>
	);
};

export default Services;
