import {
  createMuiTheme,
  responsiveFontSizes,
  ThemeProvider
} from '@material-ui/core/styles';
import React from 'react';
import { BrowserRouter, Route } from 'react-router-dom';
import About from './pages/About';
import Contact from './pages/Contact';
import Home from './pages/Home';
import Service_Category from './pages/MiniServices';
import ServiceSubCategory from './pages/ServiceCategoryDescription';
import Services from './pages/Solutions';
import ServiceDetailedCategory from './pages/SubDetailedServices';
import ScrollToTop from './utils/ScrollToTop';

function App() {
  let themes = createMuiTheme({
    typography: {
      fontFamily: ['Roboto'].join(','),
    },
  });
  themes = responsiveFontSizes(themes);
  return (
    <BrowserRouter>
      <ScrollToTop />
      <ThemeProvider theme={themes}>
        <Route exact path='/contact' component={Contact} />
        <Route exact path='/about' component={About} />
        <Route exact path='/solutions' component={Services} />
        <Route exact path='/' component={Home} />

        <Route
          exact
          path='/service-category/:service'
          component={Service_Category}
        />
        <Route
          exact
          path='/service-category/:service/:sub_service'
          component={ServiceSubCategory}
        />
        <Route
          exact
          path='/solutions/:service'
          component={ServiceDetailedCategory}
        />
      </ThemeProvider>
    </BrowserRouter>
  );
}

export default App;
