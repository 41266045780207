import React from "react";
import TopBar from "../components/TopBar";
import MenuBar from "../components/MenuBar";
import ContactSection from "../components/contact/ContactSection";
import ContactForm from "../components/contact/ContactForm";
import { Divider } from "@material-ui/core";
import LocationContact from "../components/LocationContact";

import Footer from "../components/Footer";
const Contact = () => {
	return (
		<>
			<TopBar />
			<Divider style={{ width: "100 %" }} />
			<MenuBar />
			<Divider style={{ width: "100 %" }} />

			<div style={{ marginTop: "2%" }}>
				{" "}
				<LocationContact />{" "}
			</div>
			<ContactForm />

			<ContactSection />
			<div style={{ marginTop: "calc(80vh)" }}>
				<Footer />
			</div>
		</>
	);
};

export default Contact;
