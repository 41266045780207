import { Button, Divider, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import React, { useEffect, useState } from 'react';
import { Link, useHistory, useLocation, useParams } from 'react-router-dom';
import Footer from '../components/Footer';
import MenuBar from '../components/MenuBar';
import TopTitle from '../components/service/SubMiniTitle.js';
import TopBar from '../components/TopBar';

const useStyles = makeStyles((theme) => ({
  buttonContent: {
    height: '52px',
    width: '134px',
    color: 'white',
    paddingTop: 14,
    paddingBottom: 14,
    paddingRight: 20,
    paddingLeft: 20,
    borderRadius: 0,
    fontSize: 12,
    background: '#fe6600',
    whiteSpace: 'nowrap',
    transition: '0.5s',
    '&:hover': {
      background: '#0e92d0',
    },
  },
}));
const ServiceCategoryDescription = () => {
  const classes = useStyles();
  const location = useLocation();
  let history = useHistory();
  const { sub_service } = useParams();
  console.log(sub_service);
  const section = location.pathname.split('/service-category/description/')[1];
  const [link, setLink] = useState('');
  useEffect(() => {
    try {
      if (location.pathname.split('description/')[1] !== undefined) {
        switch (location.pathname.split('description/')[1].toUpperCase()) {
          case 'AUTOMATION':
            setLink('automation');
            break;
          case 'CONTROLS':
            setLink('controls');
            break;
          case 'INSTRUMENTATION':
            setLink('instrumentation');
            break;
          case 'CONSULTING':
            setLink('consulting');
            break;

          default:
            break;
        }
      } else {
        throw 'Invalid Page';
      }
    } catch (error) {
      console.log(error);
      history.push('/');
    }
  }, [link]);
  const getMoreInfoFrom = () => {
    let route = '/solutions';
    let sub = sub_service.toUpperCase();
    switch (sub) {
      case 'AUTOMATION':
        route = 'automation';
        break;
      case 'CONTROLS':
        route = 'controls';
        break;
      case 'CONSULTING':
        route = 'consulting';
        break;
      case 'INSTRUMENTATION':
        route = 'instrumentation';
        break;

      default:
        break;
    }
    return route;
  };

  return (
    <>
      <TopBar />
      <Divider style={{ width: '100 %' }} />
      <MenuBar />
      <Divider style={{ width: '100 %' }} />
      <TopTitle />
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',

          width: '100%',
        }}
      >
        {link !== '' && description(link)}

        <div
          style={{ paddingRight: '5%', paddingLeft: '5%', paddingBottom: '5%' }}
        >
          <Link
            to={`/service-category/${getMoreInfoFrom()}`}
            style={{ textDecoration: 'none' }}
          >
            <Button variant='contained' className={classes.buttonContent}>
              <strong>More Information</strong>
            </Button>
          </Link>
        </div>
      </div>
      {/* <Content /> */}

      <Footer />
    </>
  );
};
function description(value) {
  console.log(value.toString());
  switch (value) {
    case 'instrumentation':
      return (
        <div style={{ padding: '5%' }}>
          <Typography style={{ color: '#222222' }}>
            Engineers use sensors and various other types of instrumentation to
            monitor and maintain process control equipment for improved
            production, product consistency and quality management, and
            workplace safety in manufacturing and processing facilities.
            <br></br> We are equipped to support you on various types of
            instrumentation solution used to measure temperature, gas and fluid
            flow rates, fluid levels, pressure, and pH in production, among
            others.
          </Typography>
        </div>
      );
    case 'controls':
      return (
        <div style={{ padding: '5%' }}>
          <Typography style={{ color: '#222222', marginBottom: '2vh' }}>
            Flexpower Automation and controls design and manufactures
            high-quality switchboards and control panels for use widely in
            electrical installation in industrial and commercial setups. We also
            offer product replacements, panel retrofits, upgrades, installation,
            commissioning, and maintenance.
          </Typography>
          <Typography style={{ color: '#222222', marginBottom: '2vh' }}>
            <strong>
              Some of the products you designed in our workshop include.
            </strong>
          </Typography>
          <Typography style={{ color: '#222222' }}>
            <strong>Products</strong>
          </Typography>
          <ul>
            <li>
              {' '}
              <Typography style={{ color: '#222222' }}>
                Low voltage switchgear-ACB, MCCBS, MCBs, Contactors, and all LV
                Switchgears.
              </Typography>
            </li>
            <li>
              <Typography style={{ color: '#222222' }}>Cables</Typography>
            </li>
            <li>
              <Typography style={{ color: '#222222' }}>
                UPS & Voltage stabilizers
              </Typography>
            </li>
            <li>
              <Typography style={{ color: '#222222' }}>Enclosures.</Typography>
            </li>
          </ul>
          <Typography style={{ color: '#222222', marginBottom: '2vh' }}>
            <strong>Solutions</strong>
          </Typography>
          <Typography style={{ color: '#222222', marginBottom: '2vh' }}>
            <strong>
              Design and fabrication of various Electrical Panels including:
            </strong>
          </Typography>

          <ul>
            <li>
              {' '}
              <Typography style={{ color: '#222222' }}>
                Low voltage Switchboard and distribution boards.
              </Typography>
            </li>
            <li>
              <Typography style={{ color: '#222222' }}>
                Automatic Power factor correction banks
              </Typography>
            </li>
            <li>
              <Typography style={{ color: '#222222' }}>
                Motor Control Centre panels- Star-delta, Soft starter, VFD &
                Intelligent PLC controlled panels.
              </Typography>
            </li>
            <li>
              <Typography style={{ color: '#222222' }}>
                Heater Control Panels
              </Typography>
            </li>
            <li>
              <Typography style={{ color: '#222222' }}>
                Automatic Mains Failures for various types
              </Typography>
            </li>
            <li>
              <Typography style={{ color: '#222222' }}>
                Customized Pump control panels
              </Typography>
            </li>
          </ul>
        </div>
      );
    case 'consulting':
      return (
        <div style={{ padding: '3%' }}>
          <Typography style={{ color: '#222222' }}>
            Today, the design of industrial plants is often defined by
            continuously changing production processes so it's essential that
            your system can cope. Flexpower Automation is well equipped with the
            know-how required to create solutions that are right for you. Our
            consulting services have been designed to spotlight problem areas
            and find solutions that will not only save you money but also ensure
            your systems run efficiently over due course of time. We offer the
            full range of automation consulting services including :
          </Typography>

          <ul>
            <li>
              {' '}
              <Typography style={{ color: '#222222' }}>Diagnostics</Typography>
            </li>
            <li>
              <Typography style={{ color: '#222222' }}>
                Application design
              </Typography>
            </li>
            <li>
              <Typography style={{ color: '#222222' }}>Training</Typography>
            </li>
            <li>
              <Typography style={{ color: '#222222' }}>
                Turnkey engineering projects
              </Typography>
            </li>
          </ul>
        </div>
      );
    case 'automation':
      return (
        <div style={{ padding: '5%' }}>
          <Typography style={{ color: '#222222', marginBottom: '2vh' }}>
            We design automation solutions for both your process and machine
            automation from a completely new system or retrofit old
            installations. When you work with our team, you will benefit from
            their wide range of expertise and training on various Programmable
            logic Controllers, Drives, and HMIs. We will provide you with
            solutions based on various automation Platforms including Siemens,
            Schneider Electric, Omron PLCs, Delta, and many others. All our
            solutions are designed locally and therefore you are assured of
            continuous support for your installation as well as training for
            your personnel on the installed equipment.
          </Typography>
          <Typography style={{ color: '#222222' }}>
            <strong>Some of the solutions you will get are:</strong>•
          </Typography>
          <ul>
            <li>
              {' '}
              <Typography style={{ color: '#222222' }}>
                Programming of PLC, HMI, and VFD, installation & commissioning.
              </Typography>
            </li>
            <li>
              <Typography style={{ color: '#222222' }}>
                Supervisory Control and Data Acquisition (SCADA)
              </Typography>
            </li>
            <li>
              <Typography style={{ color: '#222222' }}>
                Pump monitoring systems (PMS)
              </Typography>
            </li>
            <li>
              <Typography style={{ color: '#222222' }}>
                Automatic tank gauging systems (ATGS)
              </Typography>
            </li>
            <li>
              <Typography style={{ color: '#222222' }}>
                Tank monitoring & Automation system
              </Typography>
            </li>
            <li>
              <Typography style={{ color: '#222222' }}>
                Automatic gates & school bells
              </Typography>
            </li>
            <li>
              <Typography style={{ color: '#222222' }}>
                Automating lighting
              </Typography>
            </li>
            <li>
              <Typography style={{ color: '#222222' }}>
                Remote wireless monitoring & control systems.
              </Typography>
            </li>
          </ul>
          <Typography style={{ color: '#222222' }}>
            <strong>Products Spares.</strong>
          </Typography>

          <ul>
            <li>
              {' '}
              <Typography style={{ color: '#222222' }}>PLC & HMI</Typography>
            </li>
            <li>
              <Typography style={{ color: '#222222' }}>
                SINAMICS V20 & G120 drives
              </Typography>
            </li>
            <li>
              <Typography style={{ color: '#222222' }}>
                Soft starters
              </Typography>
            </li>
            <li>
              <Typography style={{ color: '#222222' }}>
                Radioline Wireless Communication Systems
              </Typography>
            </li>
          </ul>
        </div>
      );

    default:
      return (
        <Typography style={{ color: '#222222' }}>PAGE UNAVAILABLE</Typography>
      );
  }
}

export default ServiceCategoryDescription;
