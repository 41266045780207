import {
  Button,
  Container, Grid,
  Link, Typography
} from '@material-ui/core';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import LocationOnIcon from '@material-ui/icons/LocationOn';
import PhoneIcon from '@material-ui/icons/Phone';
import ScheduleIcon from '@material-ui/icons/Schedule';
import React from 'react';
import ScrollAnimation from 'react-animate-on-scroll';
import { my_strings } from '../values/strings';

const useStyles = makeStyles((theme) => ({
  buttonContent: {
    color: 'white',
    paddingTop: 14,
    paddingBottom: 14,
    paddingRight: 20,
    paddingLeft: 20,
    borderRadius: 0,
    fontSize: 12,
    background: '#fe6600',
    float: 'right',
  },
  root: {},
  map: {},
}));

const ContactSection = (props) => {
  const classes = useStyles();
 
  //const matches = useMediaQuery(theme.breakpoints.down("sm"));

  const mapStyles = {
    width: `calc(100%)`,
    height: 'calc(100vh*0.7)',
  };
  return (
    <div className={classes.root}>
      <Typography
        style={{ color: '#000031', marginLeft: '5%', paddingTop: '5vh' }}
        component='h4'
        variant='h4'
      >
        <strong>Call or Email Us</strong>
      </Typography>
      <Container>
        <ScrollAnimation
          offset={50}
          animateOnce='true'
          animateIn='animate__animated animate__slideInUp'
        >
          <Grid container sm='12' xs='12'>
            <Grid
              style={{ paddingTop: '5vh' }}
              container
              direction='row'
              item
              sm='4'
              xs='12'
            >
              <Grid item sm='2' xs='2'>
                <LocationOnIcon style={{ color: '#fe6600' }} />
              </Grid>
              <Grid item direction='column' sm='10' xs='10'>
                <Typography
                  variant='body1'
                  component='p'
                  style={{ color: '#0e92d0', fontSize: 14 }}
                >
                  <strong> ADDRESS</strong>
                </Typography>
                <Typography style={{ color: '#828282', marginTop: 20 }}>
                  P.O Box 61094-00200.Nairobi
                </Typography>
              </Grid>
            </Grid>

            <Grid style={{ paddingTop: '5vh' }} container item sm='4' xs='12'>
              <Grid item xs='2'>
                <ScheduleIcon style={{ color: '#fe6600' }} />
              </Grid>

              <Grid item direction='column' xs='10'>
                <Typography
                  variant='body1'
                  component='p'
                  style={{ color: '#0e92d0', fontSize: 14 }}
                >
                  <strong> WORKING HOURS</strong>
                </Typography>
                <Typography style={{ color: '#828282', marginTop: 20 }}>
                  {'Mon - Fri: 8:00 - 5:00 \n Closed on Weekends'}
                </Typography>
              </Grid>
            </Grid>

            <Grid container style={{ paddingTop: '5vh' }} item sm='4' xs='12'>
              <Grid item xs='2'>
                <PhoneIcon style={{ color: '#fe6600' }} />
              </Grid>
              <Grid item direction='column' xs='10'>
                <Typography
                  variant='body1'
                  component='p'
                  style={{ color: '#0e92d0', fontSize: 14 }}
                >
                  <strong>PHONE NUMBER</strong>
                </Typography>
                <Typography style={{ color: '#828282', marginTop: 20 }}>
                  <Link
                    href={`tel:${my_strings.contact.phonenumber}`}
                    style={{ color: '#828282' }}
                  >
                    {my_strings.contact.phonenumber}
                  </Link>
                </Typography>
              </Grid>
            </Grid>
          </Grid>
        </ScrollAnimation>
      </Container>
      <div
        style={{
          width: '100%',
          alignContent: 'right',
          backgroundColor: '#000000',
        }}
      >
        <Link
          href={`tel:${my_strings.contact.phonenumber}`}
          style={{ color: '#ffffff' }}
        >
          <Button variant='contained' className={classes.buttonContent}>
            {' '}
            <PhoneIcon />
            <strong>Call Now</strong>
          </Button>
        </Link>
      </div>
    </div>
  );
};

export default ContactSection;
