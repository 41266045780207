import {
	Divider,
	Grid,
	Hidden,
	Typography,
	useMediaQuery
} from "@material-ui/core";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import React from "react";
import { Link, useLocation } from "react-router-dom";
import Logo from "../images/icon.jpg";
import arrow_right from "../images/white_arrow_right.png";
import SwipeableTemporaryDrawer from "./Drawer";

const useStyles = makeStyles((theme) => ({
	centerFont: {
		fontSize: "14px",
		fontFamily: "Roboto",
		color: "#102027",
		transition: "0.5s",
		"&:hover": {
			color: "#fe6600",
		},
		marginLeft: 40,
	},
	containerRoot: {
		display: "flex",
		flexDirection: "column",
		color: "#052451",

		justifyContent: "center",
		alignItems: "center",
		fontSize: 9,
		fontStyle: "bold",
	},
	iconFont: {
		color: "#001970",
		fontSize: "medium",
		marginRight: 5,
		marginLeft: 5,
	},
	menuContainer: {
		height: "calc(100vh*0.13)",
	},

	quoteButton: {
		background: "#fe6600",
		width: "174px",
		height: "80px",
		display: "flex",
		justifyContent: "center",
		alignItems: "center",

		flexDirection: "row",

		transition: "0.5s",
		"&:hover": {
			background: "#0e92d0",
		},
	},
	root: {
		display: "flex",
		height: "calc(100vh*0.13)",
	},
}));

const MenuBar = () => {
	let location = useLocation();

	const classes = useStyles();
	const theme = useTheme();
	const matches = useMediaQuery(theme.breakpoints.down("md"));

	return (
		<div className={classes.root}>
			<Grid container item alignItems="center">
				<Grid item sm="3" xs="5">
					<strong className={classes.containerRoot}>
						<img
							src={Logo}
							alt="logo"
							style={{
								objectFit: `${matches ? "contain" : "cover"}`,
								height: "calc(100vh*0.13)",
								width: `${matches ? "100%" : "100%"}`,
							}}
						/>
					</strong>
				</Grid>

				<Grid
					className={classes.menuContainer}
					container
					item
					alignItems="center"
					sm="7"
					xs="5"
				>
					<Divider orientation="vertical" flexItem />
					<Hidden smDown>
						<Typography variant="H4" component="p">
							<Link
								className={classes.centerFont}
								style={{
									color: `${location.pathname === "/" && "#fe6600"}`,
									textDecoration: "none",
								}}
								to="/"
							>
								HOME
							</Link>
						</Typography>
						<Typography variant="H6" component="p">
							<Link
								className={classes.centerFont}
								style={{
									color: `${location.pathname === "/solutions" && "#fe6600"}`,
									textDecoration: "none",
								}}
								to="/solutions"
							>
								SOLUTIONS
							</Link>
						</Typography>
						<Typography variant="H6" component="p">
							<Link
								className={classes.centerFont}
								style={{
									color: `${location.pathname === "/about" && "#fe6600"}`,
									textDecoration: "none",
								}}
								to="/about"
							>
								ABOUT US
							</Link>
						</Typography>
						<Typography variant="H6" component="p">
							<Link
								className={classes.centerFont}
								style={{
									color: `${location.pathname === "/contact" && "#fe6600"}`,
									textDecoration: "none",
								}}
								to="/contact"
							>
								CONTACT US
							</Link>
						</Typography>
					</Hidden>
				</Grid>
				<Hidden mdDown>
					<Grid
						container
						item
						style={{ display: "flex", justifyContent: "flex-end" }}
						sm="2"
						xs="2"
					>
						<Link to="/contact" style={{ textDecoration: "none" }}>
							<div className={classes.quoteButton}>
								<Typography
									style={{
										color: "white",
										fontSize: "15px",
										fontFamily: "Roboto",
										letterSpacing: "0.67px",
										fontStyle: "bold",
									}}
									variant="H6"
									component="p"
								>
									<strong>GET A QUOTE</strong>
								</Typography>

								<img
									alt="quote"
									src={arrow_right}
									style={{ width: "16px", marginLeft: "10px" }}
								/>
							</div>
						</Link>
					</Grid>
				</Hidden>
				<Hidden mdUp>
					<Grid item sm="2" xs="2">
						<SwipeableTemporaryDrawer />
					</Grid>
				</Hidden>
			</Grid>
		</div>
	);
};

export default MenuBar;
