import React from 'react';
import { Grid, Typography, Button, Container, Link } from '@material-ui/core';
import welcomeImage from '../../images/About/aboutUs.jpg';
import { makeStyles } from '@material-ui/core/styles';
import ScrollAnimation from 'react-animate-on-scroll';
import GetAppIcon from '@material-ui/icons/GetApp';
import aboutpdf from '../../documents/about.pdf';
const useStyles = makeStyles((theme) => ({
  root: {
    alignContent: 'center',
    justifyContent: 'center',
  },
  buttonContent: {
    paddingTop: 14,
    paddingBottom: 14,
    paddingRight: 20,
    paddingLeft: 20,
    borderRadius: 0,
    fontSize: 12,
    border: '2px solid #fe6600',
    color: '#fe6600',
    marginTop: '4vh',
    marginBottom: '10vh',
    transition: '0.5s',
    '&:hover': {
      background: '#fe6600',
      color: '#ffffff',
    },
  },
  image: {
    objectFit: 'cover',
    height: 'calc(100vh*0.8)',

    [theme.breakpoints.down('xs')]: {
      width: 'calc(90vw)',
      height: 'calc(100vh*0.7)',
    },
  },
  content: {},
}));
const AboutWelcomeSection = () => {
  const classes = useStyles();
  return (
    <div className='root'>
      <ScrollAnimation
        animateOnce='true'
        animateIn='animate__animated animate__slideInUp'
      >
        <Grid container className={classes.content}>
          <Grid
            className='animate__animated animate__slideInUp'
            item
            md='4'
            xs='12'
          >
            <img
              alt='about'
              style={{ width: '100%' }}
              src={welcomeImage}
              className={classes.image}
            />
          </Grid>
          <Grid
            container
            style={{ alignContent: 'center', padding: '2%' }}
            item
            md='8'
            xs='12'
          >
            <Container>
              <Typography
                style={{ color: '#0e92d0', paddingBottom: '2vh' }}
                component='h3'
                variant='h3'
              >
                <strong>Think automation... do less,for more</strong>
              </Typography>
              <Typography
                style={{
                  color: '#222222',
                  marginBottom: '20',
                  fontFamily: 'Roboto',
                  fontSize: '15px',
                }}
                component='body1'
                variant='p'
              >
                Flexpower Automation & Controls is all about passion in the
                field of Automation, both Industrial and Building Automation. We
                are inspired to excellence through a passion for what we do. The
                driving force combined with professional service enables us to
                deliver you solutions that not only satisfy your needs but also
                lay the foundation for continued support.
                <br />
                <br /> We provide a host of Electrical services that include all
                Building blocks necessary to automate various processes. We are
                a one-stop, full-service, engineering resource for industrial
                automation solutions. We offer custom controls, integration, and
                process design services for the materials handling,
                manufacturing, and distribution industries.
                <br />
                <br /> Our experience, passion and proven track record for
                solving even the most perplexing production challenges have
                established us as their partner of choice when the best result
                is the only option. We have become the authoritative voice of
                experience they listen to. One reason for that is how well we
                listen to our customers; understanding their needs and the
                results they require. Again, and again, our customers call on us
                as their partner of choice.
              </Typography>
              <div>
                <Button variant='outlined' className={classes.buttonContent}>
                  <Link
                    href='/about#description'
                    style={{ color: '#0e92d0', textDecoration: 'none' }}
                  >
                    <strong>About Us</strong>
                  </Link>
                </Button>
                <Button
                  startIcon={<GetAppIcon />}
                  variant='outlined'
                  style={{ marginLeft: '1rem' }}
                  className={classes.buttonContent}
                >
                  <a
                    target='_blank'
                    rel='noopener'
                    href={aboutpdf}
                    download
                    style={{ color: '#0e92d0', textDecoration: 'none' }}
                  >
                    <strong>Download Company Profile</strong>
                  </a>
                </Button>
              </div>
            </Container>
          </Grid>
        </Grid>
      </ScrollAnimation>
    </div>
  );
};

export default AboutWelcomeSection;
