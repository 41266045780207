import { Grid, Typography, useMediaQuery } from "@material-ui/core";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import React from "react";
import ScrollAnimation from "react-animate-on-scroll";
const useStyles = makeStyles((theme) => ({
	root: {
		fontFamily: "Roboto",
	},
	buttonContent: {
		paddingTop: 14,
		paddingBottom: 14,
		paddingRight: 20,
		paddingLeft: 20,
		borderRadius: 0,
		fontSize: 12,
		border: "1px solid #fe6600",
		color: "#fe6600",
		marginTop: 50,
		"&:hover": {
			background: "#fe6600",
			color: "#ffffff",
		},
	},
	content: {
		backgroundColor: "#f5f5f5",
		paddingBottom: "10vh",
	},
}));
const AboutDescription = () => {
	const classes = useStyles();
	const theme = useTheme();
	const matches = useMediaQuery(theme.breakpoints.down("sm"));
	return (
		<div className={classes.root}>
			<Grid container className={classes.content}>
				<Grid
					id="description"
					container
					style={{
						alignContent: "center",
					}}
					item
					sm="5"
					xs="12"
				>
					<ScrollAnimation
						animateOnce="true"
						animateIn="animate__animated animate__slideInUp"
					>
						<Typography
							style={{
								color: "#0e92d0",
								width: "80%",
								paddingBottom: 10,
								padding: "5%",
							}}
							component="h2"
							variant="h2"
						>
							<strong>A true partnership, you can rely on.</strong>
						</Typography>
					</ScrollAnimation>
				</Grid>
				<Grid
					container
					style={{ alignContent: "center", padding: "5%" }}
					item
					sm="7"
					xs="12"
				>
					<ScrollAnimation
						animateOnce="true"
						animateIn="animate__animated animate__slideInUp"
					>
						<div>
							<Typography
								style={{
									marginBottom: "2vh",
								}}
								component="h5"
								variant="h5"
							>
								<strong
									style={{
										color: "#0e92d0",

										borderBottom: "3px solid #fe6600",
									}}
								>
									Vision
								</strong>
							</Typography>
						</div>
						<div>
							<div style={{ marginBottom: "5vh" }}>
								<Typography
									style={{
										color: "#222222",
										fontSize: "15px",
									}}
									component="body1"
									variant="p"
								>
									To be the leading automation system integrator in East Africa
									in both market share and reputation.
								</Typography>
							</div>
							<Typography
								style={{
									marginBottom: "2vh",
								}}
								component="h5"
								variant="h5"
							>
								<strong
									style={{
										color: "#0e92d0",
										borderBottom: "3px solid #fe6600",
									}}
								>
									Mission
								</strong>
							</Typography>
							<Typography
								style={{
									color: "#222222",
								}}
								component="body1"
								variant="p"
							>
								Flexpower Automation and Control is committed to serving and
								educating our customers on achieving better results for their
								business understanding their needs and meeting them by providing
								the best in class industrial solutions, technology, and
								equipment.
							</Typography>
						</div>
					</ScrollAnimation>
				</Grid>
			</Grid>
		</div>
	);
};

export default AboutDescription;
